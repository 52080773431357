import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box, CssBaseline, ThemeProvider } from '@mui/material';
import theme from './view/theme';
import MyAppBar from './view/MyAppBar';
import Auth from './view/auth/Auth';
import Fetch from './view/FetchData';

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Auth>
        <Fetch />
        <MyAppBar />
        <Box sx={{ paddingTop: '80px' }}><Outlet /></Box>
      </Auth>
    </ThemeProvider>
  );
};

export default App;
