import {
  BaseModel,
  emptyBaseModel,
  PageFetchParams,
} from '../common/commonModel';
import { Member, emptyMember } from '../member/memberModel';
import { TopUp } from '../topUp/topUpModel';

enum DonationType {
  OneTime = 'OneTime',
  Monthly = 'Monthly',
}

interface Donation extends BaseModel {
  readonly id: number;
  readonly memberId: string;
  readonly fundraiserId: string;
  readonly member: Member;
  readonly type: DonationType;
  readonly amount: number;
  readonly donatedDate: string;
  readonly topUpId?: number;
  readonly topUp?: TopUp;

  readonly isCanceled?: boolean;
}

const emptyDonation: Donation = {
  id: 0,
  memberId: '',
  fundraiserId: '',
  member: emptyMember,
  type: DonationType.OneTime,
  amount: 0,
  donatedDate: '',
  ...emptyBaseModel,
};

interface DonationFetchParams extends PageFetchParams {
  memberId?: string;
}

export {
  type Donation,
  DonationType,
  type DonationFetchParams,
  emptyDonation,
};
