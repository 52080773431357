import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { useAuthService } from '../../data/auth/authService';
import Login from './Login';
import Logo from '../../assets/logo.png';
import { Colors } from '../../colors';
var Spinner = require('react-spinkit');

const Auth: React.FC<React.PropsWithChildren> = (props) => {
  const { isLoggedOut, isAuthenticated, setupAuth } = useAuthService();

  useEffect(setupAuth, [setupAuth]);

  const Loading = () => {
    return (
      <Box sx={{ paddingY: '25px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ height: '25px' }} />
        <img src={Logo} width={200} alt='logo' />
        <Spinner name="line-scale-pulse-out" color={Colors.primaryColor} fadeIn="none" />
      </Box>
    )
  }

  return (
    <React.Fragment>
      {isLoggedOut ? (
        <Login />
      ) : isAuthenticated ? (
        props.children
      ) : (
        <Loading />
      )}
    </React.Fragment>
  );
};

export default Auth;
