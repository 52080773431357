import { BaseModel, emptyBaseModel } from '../common/commonModel';

interface Fundraiser extends BaseModel {
  id: string;
  readonly country?: string;
  readonly defaultCurrencyId?: string;
  readonly fundraiserCashList?: readonly FundraiserCash[];
  readonly dataMigration?: boolean;
}

interface FundraiserCash extends BaseModel {
  readonly id: string;
  readonly fundraiserId: string;
  readonly currencyId: string;
  readonly remainingCashAmount: number;
  readonly totalCollectedCashAmount: number;
}

const emptyFundraiser: Fundraiser = {
  id: '',
  ...emptyBaseModel,
};

const emptyFundraiserCash: FundraiserCash = {
  id: '',
  fundraiserId: '',
  currencyId: '',
  remainingCashAmount: 0,
  totalCollectedCashAmount: 0,
  ...emptyBaseModel,
};

export { type Fundraiser, type FundraiserCash, emptyFundraiser, emptyFundraiserCash };
