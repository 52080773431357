import { Box, Typography } from "@mui/material"
import { Colors } from "../../../colors"

export const MemberDetailsDataRow: React.FC<{ title: string, data: string }> = (props) => {
  const { title, data } = props;

  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginY: '20px'
      }}
    >
      <Typography variant="body1" color={Colors.textColor}>{title}</Typography>
      <Typography variant="body1" color={Colors.primaryColor}>{data}</Typography>
    </Box>
  )
}