import { Box, Typography } from "@mui/material";
import { Colors } from "../../../colors";
import { getFormattedDate, getString } from "../../../util/helpers";
import Moment from 'moment';

interface CollectionTableRowProps {
  header?: boolean;
  date: Moment.MomentInput;
  remark: string;
  currency: string;
  amount: number;
}

export const CollectionTableRow: React.FC<CollectionTableRowProps> = (props: CollectionTableRowProps) => {
  const {
    header = false,
    date,
    remark,
    currency,
    amount,
  } = props;

  const Item: React.FC<{ title: string, alignment?: 'start' | 'center' | 'end' }> = ({ title, alignment = 'center' }) => {
    return (
      <Typography
        variant='body1'
        color={Colors.primaryColor}
        sx={{
          flex: 1,
          display: 'flex',
          justifyContent: alignment
        }}
      >
        {title}
      </Typography>
    )
  }

  return (
    <Box
      sx={[{
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '50px',
        paddingX: '40px',
        paddingY: '20px',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderTopWidth: 0,
        borderColor: Colors.borderColor,
      },
      header && {
        borderTopWidth: '1px',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        borderColor: Colors.borderColor,
        borderBottomColor: Colors.primaryColor,
        backgroundColor: Colors.accentColor,
      }]}
    >
      <Item title={header ? 'Date' : getFormattedDate(date)} alignment='start' />
      <Item title={header ? 'Remark' : getString(remark)} />
      <Item title={header ? 'Currency' : getString(currency)} />
      <Item title={header ? 'Amount' : amount.toLocaleString()} />
    </Box>
  )
}