import { Pagination } from "@mui/material"

interface PaginationProps {
  page: number;
  total: number;
  onChange: (page: number) => void;
}

export const MHLPagination: React.FC<PaginationProps> = ({ page, total, onChange }) => {
  return (
    <Pagination
      page={page + 1}
      count={total}
      shape="rounded"
      size="large"
      color="primary"
      onChange={(event, page) => onChange(page - 1)}
    />
  )
}