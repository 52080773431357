import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { Colors } from '../../colors';
import { MHLButton } from '../../components/buttons';
import { MHLInput } from '../../components/inputs';
import { useAuthService } from '../../data/auth/authService';
import { getErrorMessage } from '../../util/helpers';
import Logo from '../../assets/logo.png';

const Login: React.FC = () => {
  const { login } = useAuthService();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const disabled = () => {
    return username.length === 0 || password.length === 0;
  }

  const handleLogin = () => {
    setLoading(true);
    setError('');
    login({ username: username, password: password })
      .then(() => setLoading(false))
      .catch(e => {
        setUsername('');
        setPassword('');
        setLoading(false);
        setError(getErrorMessage(e));
      });
  };

  return (
    <Box sx={{paddingX: '60px', display: 'flex', alignItems: 'center' }}>
      <Box
        sx={{
          flex: 1, 
          height: '80%',
          paddingX: '80px',
          backgroundColor: Colors.white,
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: Colors.borderColor,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Typography color={Colors.textColor} sx={{ marginBottom: '15px' }}>Welcome back</Typography>
        <Typography variant='h3' fontWeight="bold" color={Colors.primaryColor}>Login to Dashboard</Typography>
        <Box sx={{ height: '25px' }} />
        <MHLInput
          value={username}
          width='100%'
          title='Username*'
          onChange={(text) => setUsername(text)}
        />
        <Box sx={{ height: '30px' }} />
        <MHLInput
          value={password}
          type='password'
          width='100%'
          title='Password*'
          onChange={(text) => setPassword(text)}
        />
        <Box sx={{ height: '50px', display: 'flex', alignItems: 'center' }}>
          <Typography variant='body2' color={Colors.errorColor}>{error}</Typography>
        </Box>
        <MHLButton
          loading={loading}
          disabled={disabled()}
          width='100%'
          title='LOGIN'
          onClick={handleLogin}
        />
      </Box>
      <Box sx={{ width: '60px' }} />
      <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <img src={Logo} width={200} alt='logo' />
        <Box sx={{ height: '25px' }} />
        <Typography color={Colors.textColor}>Moon Halo is an indispensable support project for urban UGs.</Typography>
      </Box>
    </Box>
  );
};

export default Login;
