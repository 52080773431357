import { KeyboardArrowDown } from "@mui/icons-material";
import { Box, Button, Menu, MenuItem, Typography } from "@mui/material"
import { useState } from "react";
import { Colors } from "../colors";

interface DropdownProps {
  list: string[];
  value: string;
  onChange: (item: string) => void;
}

export const Dropdown: React.FC<DropdownProps> = (props: DropdownProps) => {
  const { list, value, onChange } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <Button
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        disableRipple
        onClick={handleClick}
        endIcon={<KeyboardArrowDown sx={{ color: Colors.primaryColor }} />}
        sx={{
          height: '40px',
          width: '150px',
          paddingX: '20px',
          backgroundColor: Colors.accentColor,
          '&:hover': {
            backgroundColor: Colors.accentColor,
            opacity: 0.9,
          },
          borderRadius: '10px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography color={Colors.primaryColor} >{value}</Typography>
      </Button>
      <Menu
        open={open}
        onClose={handleClose}
        elevation={0}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '10px',
            color: Colors.white,
            boxShadow: '0px 0px 4px 0px rgba(36, 62, 150, 0.05)',
            borderStyle: 'solid',
            borderWidth: '1px',
            borderColor: Colors.borderColor,
            '& .MuiMenu-list': {
              width: '150px',
            },
            '& .MuiMenuItem-root': {
              '&:active': {
                backgroundColor: Colors.accentColor,
              },
              '&:hover': {
                backgroundColor: Colors.backgroundColor,
              },
            },
          },
        }}
      >
        {list.map(item =>
          <MenuItem
            key={item}
            disableRipple
            sx={[value === item && { background: Colors.accentColor }]}
            onClick={() => {
              onChange(item);
              handleClose();
            }}
          >
            <Typography color={Colors.primaryColor} >{item}</Typography>
          </MenuItem>)}
      </Menu>
    </Box>
  )
}