import { Button, Typography } from "@mui/material";
import { Colors } from "../colors";
var Spinner = require('react-spinkit');

interface AppBarButtonProps extends ButtonProps {
  selected: boolean;
}

export const MHLAppBarButton: React.FC<AppBarButtonProps> = (props: AppBarButtonProps) => {
  const { selected, height, title, href, onClick } = props;

  return (
    <Button
      disableRipple
      key={href}
      href={href}
      onClick={onClick}
      sx={[
        {
          height: height ?? '80px',
          color: Colors.primaryColor,
          opacity: 0.8,
          borderRadius: 0,
          mr: '30px',
          borderTop: 3,
          borderTopColor: Colors.transparent,
          borderBottom: 3,
          borderBottomColor: Colors.transparent,
        },
        selected && { borderBottomColor: Colors.primaryColor, color: Colors.primaryColor, opacity: 1 },
      ]}
    >
      <Typography variant='body1' color={selected ? Colors.primaryColor : Colors.textColor}>
        {title}
      </Typography>
    </Button>
  )
}

interface IconButtonProps extends ButtonProps {
  reversed?: boolean;
  icon: React.ReactNode;
}

export const MHLIconButton: React.FC<IconButtonProps> = (props: IconButtonProps) => {
  const { reversed = false, icon, width, height, color, title, titleColor, onClick } = props;

  return (
    <Button
      disableElevation
      disableRipple
      variant="contained"
      startIcon={reversed ? icon : undefined}
      endIcon={reversed ? undefined : icon}
      sx={{
        height: height ?? '50px',
        width: width ?? '240px',
        backgroundColor: color ?? Colors.primaryColor,
        '&:hover': {
          backgroundColor: color ?? Colors.primaryColor,
          opacity: 0.9,
        },
        borderRadius: '10px',
      }}
      onClick={onClick}
    >
      <Typography variant='body1' color={titleColor ?? Colors.white}>
        {title}
      </Typography>
    </Button>
  )
}

interface ButtonProps {
  disabled?: boolean;
  loading?: boolean;
  width?: string;
  height?: string;
  color?: string;
  title: string;
  titleColor?: string;
  href?: string;
  onClick?: (e: any) => void;
}

export const MHLButton: React.FC<ButtonProps> = (props: ButtonProps) => {
  const {
    disabled = false,
    loading = false,
    width,
    height,
    color,
    title,
    titleColor,
    href,
    onClick
  } = props;

  return (
    <Button
      disabled={disabled || loading}
      disableElevation
      disableRipple
      variant="contained"
      href={href}
      sx={{
        height: height ?? '50px',
        width: width ?? '240px',
        backgroundColor: color ?? Colors.primaryColor,
        '&:hover': {
          backgroundColor: color ?? Colors.primaryColor,
          opacity: 0.9,
        },
        '&:disabled': {
          backgroundColor: (color ?? Colors.primaryColor),
          opacity: 0.2,
        },
        borderRadius: '10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      onClick={onClick}
    >
      {loading ?
        <Spinner name="line-scale-pulse-out" color={Colors.primaryColor} fadeIn="none" /> :
        <Typography variant='body1' color={titleColor ?? Colors.white}>
          {title}
        </Typography>}
    </Button>
  )
}