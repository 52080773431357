import { Box, Typography } from '@mui/material';
import { Colors } from '../colors';
import { Member } from '../data/member/memberModel';

interface MemberInfoProps {
  member: Member;
}

export const MHLMemberInfo: React.FC<MemberInfoProps> = (
  props: MemberInfoProps
) => {
  const { member } = props;

  return (
    <Box sx={{ flex: 1 }}>
      <Typography variant='body1' color={Colors.primaryColor}>
        Member Info
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-end',
          marginTop: '5px',
        }}
      >
        <Typography
          variant='h4'
          color={Colors.primaryColor}
          sx={{ marginRight: '10px' }}
        >
          {member.name}
        </Typography>
        <Typography
          variant='body1'
          color={Colors.primaryColor}
          sx={{ marginBottom: '5px' }}
        >
          ({member.id})
        </Typography>
      </Box>
    </Box>
  );
};
