import { useEffect } from "react";
import { useAuthService } from "../data/auth/authService";
import { useCurrencyRepository } from "../data/currency/currencyRepository";
import { useFundraiserRepository } from "../data/fundraiser/fundraiserRepository";

export default function Fetch() {
  const { user } = useAuthService();
  const { fetchFundraiserById } = useFundraiserRepository();
  const { fetchCurrencys } = useCurrencyRepository();

  useEffect(() => {
    if (user?.fundraiserId) {
      fetchFundraiserById(user?.fundraiserId);
      fetchCurrencys();
    }
  }, [fetchFundraiserById, user?.fundraiserId, fetchCurrencys]);

  return (
    <></>
  )
}