export const Colors = {
  backgroundColor: '#F9FAFF',
  primaryColor: '#0C226E',
  accentColor: '#F1F3FB',
  borderColor: '#E7E9F1',
  textColor: '#4D5A7C',
  errorColor: '#C02026',
  white: '#FFFFFF',
  black: '#000000',
  blue: '#0066FF',
  green: '#52CD66',
  transparent: 'transparent',
}