import { BaseModel, emptyBaseModel } from '../common/commonModel';

interface Member extends BaseModel {
  readonly id: string;
  readonly name: string;
  readonly contactPoint: string;
  readonly email: string;
  readonly fundraiserId: string;
  readonly monthlyDonation: number;
  readonly remainingAmount: number;
  readonly totalDonatedAmount: number;
  readonly totalDonatedMonth: number;
}

const emptyMember: Member = {
  id: '',
  name: '',
  contactPoint: '',
  email: '',
  fundraiserId: '',
  monthlyDonation: 0,
  remainingAmount: 0,
  totalDonatedAmount: 0,
  totalDonatedMonth: 0,
  ...emptyBaseModel,
};

export { type Member, emptyMember };
