import { DisabledByDefaultOutlined } from "@mui/icons-material";
import { Box, Dialog, Divider, IconButton, Typography } from "@mui/material";
import React from "react";
import { Colors } from "../colors";
import { MHLButton } from "./buttons";

interface PopUpProps {
  width?: string;
  height?: string;
  show: boolean;
  error?: string;
  title: string;
  children: React.ReactNode;
  barrierDismissible?: boolean;
  onClose: () => void;
  cancelButtonText: string;
  onCancel: () => void;
  submitButtonLoading?: boolean;
  submitButtonText: string;
  onSubmit: () => void;
}

export const MHLPopUp: React.FC<PopUpProps> = (props: PopUpProps) => {
  const {
    width,
    height,
    show,
    error,
    title,
    children,
    barrierDismissible = false,
    onClose,
    cancelButtonText = 'Cancel',
    onCancel,
    submitButtonLoading = false,
    submitButtonText = 'Submit',
    onSubmit,
  } = props;

  return (
    <Dialog
      open={show}
      maxWidth="xl"
      onClose={barrierDismissible ? onClose : () => { }}
      PaperProps={{
        style: {
          width: width ?? '700px',
          height: height ?? '560px',
          borderRadius: '20px',
          color: Colors.white,
        }
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingX: '30px',
          paddingTop: '30px'
        }}
      >
        <Typography
          fontSize='25px'
          fontWeight='bold'
          color={Colors.primaryColor}
        >
          {title}
        </Typography>
        {!barrierDismissible &&
          <IconButton
            disableRipple
            disableFocusRipple
            disableTouchRipple
            onClick={onClose}
            size="small"
          >
            <DisabledByDefaultOutlined sx={{ color: Colors.primaryColor }} />
          </IconButton>}
      </Box>
      <Divider sx={{ marginX: '30px', marginY: '20px', borderColor: Colors.borderColor }} />
      {children}
      <Box sx={{ marginX: '30px', height: '40px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <Typography variant="body2" color={Colors.errorColor} >{error}</Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'end',
          alignItems: 'center',
          marginX: '30px',
          marginBottom: '30px'
        }}
      >
        <MHLButton
          width="160px"
          color={Colors.accentColor}
          titleColor={Colors.primaryColor}
          title={cancelButtonText}
          onClick={onCancel}
        />
        <Box sx={{ width: '20px' }} />
        <MHLButton
          loading={submitButtonLoading}
          width="160px"
          title={submitButtonText}
          onClick={onSubmit}
        />
      </Box>
    </Dialog>
  )
}