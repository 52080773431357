import { Box, Typography } from '@mui/material';
import { Colors } from '../colors';

interface PriceProps {
  title: string;
  unit: string;
  price: number;
}

export const MHLPrice: React.FC<PriceProps> = (props: PriceProps) => {
  const { title, unit, price } = props;

  return (
    <Box>
      <Typography variant='body1' color={Colors.primaryColor}>
        {title}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: '5px',
        }}
      >
        <Typography
          variant='body1'
          color={Colors.primaryColor}
          sx={{ marginTop: '5px', marginRight: '10px' }}
        >
          {unit}
        </Typography>
        <Typography variant='h3' color={Colors.primaryColor}>
          {price.toLocaleString()}
        </Typography>
      </Box>
    </Box>
  );
};
