import { ArrowBack } from '@mui/icons-material';
import { Box, Divider, List, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Colors } from '../../colors';
import {
  MHLAppBarButton,
  MHLButton,
  MHLIconButton,
} from '../../components/buttons';
import { MHLPopUp } from '../../components/popup';
import { MHLPrice } from '../../components/price';
import {
  Donation,
  DonationType,
  emptyDonation,
} from '../../data/donation/donationModel';
import { useDonationRepository } from '../../data/donation/donationRepository';
import { emptyMember, Member } from '../../data/member/memberModel';
import { useMemberRepository } from '../../data/member/memberRepository';
import { emptyTopUp, TopUp } from '../../data/topUp/topUpModel';
import { useTopUpRepository } from '../../data/topUp/topUpRepository';
import {
  getErrorMessage,
  getFormattedDate,
  getString,
} from '../../util/helpers';
import { MemberDetailsDataRow } from './components/MemberDetailsDataRow';
import { MemberDonateTableRow } from './components/MemberDonateTableRow';
import { MemberTopUpTableRow } from './components/MemberTopUpTableRow';
import CreateMemberPopUpContent from './CreateMemberPopUpContent';
import DonationPopUpContent from './DonationPopUpContent';
import TopUpPopUpContent from './TopUpPopUpContent';

function MemberDetailsPage() {
  const navigate = useNavigate();
  let params = useParams();

  const { fetchMemberById, updateMember } = useMemberRepository();
  const { topUpList, fetchTopUps, createTopUp, cancelTopUp } =
    useTopUpRepository();
  const {
    donationList,
    fetchDonations,
    createDonation,
    resendEmail,
    cancelDonation,
  } = useDonationRepository();

  const [selectedTab, setSelectedTab] = useState(0);
  let tabs = ['Top Up Records', 'Donation Records'];

  const [member, setMember] = useState<Member>(emptyMember);
  const [updatedMember, setUpdatedMember] = useState<Member>(member);

  const [showEditMemberPopUp, setShowEditMemberPopUp] = useState(false);
  const [editingMember, setEditingMember] = useState(false);
  const [editMemberError, setEditMemberError] = useState<string>();

  const [showTopUpPopUp, setShowTopUpPopUp] = useState(false);
  const [topup, setTopUp] = useState<TopUp>(emptyTopUp);
  const [loadingTopUp, setLoadingTopUp] = useState(false);
  const [topUpError, setTopUpError] = useState<string>();

  const [showDonationPopUp, setShowDonationPopUp] = useState(false);
  const [donation, setDonation] = useState<Donation>();
  const [loadingDonation, setLoadingDonation] = useState(false);
  const [donationError, setDonationError] = useState<string>();

  const [showConfirmResendEmailPopUp, setShowConfirmResendEmailPopUp] =
    useState(false);
  const [resendingEmail, setResendingEmail] = useState(false);
  const [resendEmailError, setResendEmailError] = useState<string>();

  const [showConfirmCancelTopUpPopUp, setShowConfirmCancelTopUpPopUp] =
    useState(false);
  const [loadingCancelTopUp, setLoadingCancelTopUp] = useState(false);
  const [cancelTopUpError, setCancelTopUpError] = useState<string>();

  const [showConfirmCancelDonationPopUp, setShowConfirmCancelDonationPopUp] =
    useState(false);
  const [loadingCancelDonation, setLoadingCancelDonation] = useState(false);
  const [cancelDonationError, setCancelDonationError] = useState<string>();

  useEffect(() => {
    fetchMemberById(params.memberId ?? '').then((data) => setMember(data.data));
    fetchTopUps(params.memberId ? { memberId: params.memberId } : undefined);
    fetchDonations(params.memberId ? { memberId: params.memberId } : undefined);
  }, [fetchMemberById, params.memberId, fetchTopUps, fetchDonations]);

  function _handleEdit() {
    setEditingMember(true);
    updateMember(updatedMember)
      .then(() => {
        setShowEditMemberPopUp(false);
        setEditingMember(false);
        fetchMemberById(params.memberId ?? '').then((data) =>
          setMember(data.data)
        );
      })
      .catch((e) => {
        setEditingMember(false);
        setEditMemberError(getErrorMessage(e));
      });
  }

  function _handleTopUp() {
    setLoadingTopUp(true);
    createTopUp(topup)
      .then(() => {
        setShowTopUpPopUp(false);
        setLoadingTopUp(false);
        fetchMemberById(params.memberId ?? '').then((data) =>
          setMember(data.data)
        );
        fetchTopUps(
          params.memberId ? { memberId: params.memberId } : undefined
        );
        fetchDonations(
          params.memberId ? { memberId: params.memberId } : undefined
        );
      })
      .catch((e) => {
        setTopUpError(getErrorMessage(e));
        setLoadingTopUp(false);
      });
  }

  function _handleDonation() {
    if (donation) {
      createDonation(donation)
        .then(() => {
          setShowDonationPopUp(false);
          setLoadingDonation(false);
          fetchMemberById(params.memberId ?? '').then((data) =>
            setMember(data.data)
          );
          fetchDonations(
            params.memberId ? { memberId: params.memberId } : undefined
          );
        })
        .catch((e) => {
          setDonationError(getErrorMessage(e));
          setLoadingDonation(false);
        });
    } else {
      createDonation(emptyDonation)
        .then(() => {
          setShowDonationPopUp(false);
          setLoadingDonation(false);
          fetchMemberById(params.memberId ?? '').then((data) =>
            setMember(data.data)
          );
          fetchDonations(
            params.memberId ? { memberId: params.memberId } : undefined
          );
        })
        .catch((e) => {
          setDonationError(getErrorMessage(e));
          setLoadingDonation(false);
        });
    }
  }

  function _handleCancelTopUp() {
    setLoadingCancelTopUp(true);
    cancelTopUp(topup.id.toString())
      .then(() => {
        setShowConfirmCancelTopUpPopUp(false);
        setLoadingCancelTopUp(false);
        setTopUp(emptyTopUp);
        fetchMemberById(params.memberId ?? '').then((data) =>
          setMember(data.data)
        );
        fetchTopUps(
          params.memberId ? { memberId: params.memberId } : undefined
        );
      })
      .catch((e) => {
        setCancelTopUpError(getErrorMessage(e));
        setLoadingCancelTopUp(false);
      });
  }

  function _handleResendEmail() {
    setResendingEmail(true);
    resendEmail(donation?.id.toString() ?? '')
      .then(() => {
        setShowConfirmResendEmailPopUp(false);
        setResendingEmail(false);
        setDonation(undefined);
      })
      .catch((e) => {
        setResendEmailError(getErrorMessage(e));
        setResendingEmail(false);
      });
  }

  function _handleCancelDonation() {
    setLoadingCancelDonation(true);
    cancelDonation(donation?.id.toString() ?? '')
      .then(() => {
        setShowConfirmCancelDonationPopUp(false);
        setLoadingCancelDonation(false);
        setDonation(undefined);
        fetchMemberById(params.memberId ?? '').then((data) =>
          setMember(data.data)
        );
        fetchDonations(
          params.memberId ? { memberId: params.memberId } : undefined
        );
      })
      .catch((e) => {
        setCancelDonationError(getErrorMessage(e));
        setLoadingCancelDonation(false);
      });
  }

  const Header = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <MHLIconButton
          reversed
          icon={<ArrowBack sx={{ color: Colors.primaryColor }} />}
          width='65px'
          title={'Back'}
          color={Colors.backgroundColor}
          titleColor={Colors.primaryColor}
          onClick={() => navigate(-1)}
        />
        <Box sx={{ flex: 1 }} />
        <MHLButton
          width='160px'
          title={'Edit'}
          color='#E4E9FA'
          titleColor={Colors.primaryColor}
          onClick={() => {
            setEditMemberError('');
            setShowEditMemberPopUp(true);
          }}
        />
        <Box sx={{ width: '20px' }} />
        <MHLButton
          width='160px'
          title={'Top Up'}
          onClick={() => {
            setDonation(undefined);
            setTopUpError('');
            setShowTopUpPopUp(true);
          }}
        />
        <Box sx={{ width: '20px' }} />
        <MHLButton
          disabled={member.remainingAmount === 0}
          width='160px'
          title={'Donate'}
          color={Colors.errorColor}
          titleColor={Colors.white}
          onClick={() => {
            setDonation(undefined);
            setDonationError('');
            setShowDonationPopUp(true);
          }}
        />
      </Box>
    );
  };

  const Details = () => {
    return (
      <Box
        sx={{
          width: '35%',
          paddingTop: '30px',
          paddingRight: '30px',
          borderRightWidth: '1px',
          borderRightColor: Colors.borderColor,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <MHLPrice
            title={'Current Amount'}
            unit={'MHL'}
            price={member.remainingAmount}
          />
          <MHLPrice
            title={'Total Donated Amount'}
            unit={'MHL'}
            price={member.totalDonatedAmount}
          />
        </Box>
        <Divider sx={{ marginY: '30px', borderColor: Colors.borderColor }} />
        <MemberDetailsDataRow
          title={'Member ID'}
          data={getString(params.memberId)}
        />
        <MemberDetailsDataRow title={'Name'} data={getString(member.name)} />
        <MemberDetailsDataRow
          title={'Contact Point'}
          data={getString(member.contactPoint)}
        />
        <MemberDetailsDataRow title={'Email'} data={getString(member.email)} />
        <MemberDetailsDataRow
          title={'FR Name'}
          data={getString(member.fundraiserId)}
        />
        <MemberDetailsDataRow
          title={'Monthly MHL'}
          data={member.monthlyDonation.toLocaleString()}
        />
        <MemberDetailsDataRow
          title={'Created Date'}
          data={getFormattedDate(member.createdDate)}
        />
      </Box>
    );
  };

  const History = () => {
    return (
      <Box sx={{ flex: 1 }}>
        <Box
          sx={{
            height: '60px',
            paddingLeft: '30px',
            borderBottomWidth: '1px',
            borderBottomColor: Colors.borderColor,
          }}
        >
          {tabs.map((tab) => (
            <MHLAppBarButton
              key={tab}
              selected={selectedTab === tabs.indexOf(tab)}
              height={'60px'}
              title={tab}
              onClick={() => setSelectedTab(tabs.indexOf(tab))}
            />
          ))}
        </Box>
        <Divider
          sx={{ marginBottom: '20px', borderColor: Colors.borderColor }}
        />
        {selectedTab === 0
          ? topUpList.length > 0 && (
              <List sx={{ marginLeft: '30px' }}>
                <MemberTopUpTableRow
                  header
                  date={new Date()}
                  currency={''}
                  amount={0}
                  topupMHL={0}
                  remark={''}
                />
                {topUpList.map((item) => (
                  <MemberTopUpTableRow
                    isCanceled={item.isCanceled}
                    key={item.id}
                    date={item.createdDate}
                    currency={item.currencyId}
                    amount={item.cashAmount}
                    topupMHL={item.amount}
                    remark={item.remark ?? ''}
                    onCancel={
                      item.isCanceled || !member.email
                        ? undefined
                        : () => {
                            setCancelTopUpError('');
                            setTopUp(item);
                            setShowConfirmCancelTopUpPopUp(true);
                          }
                    }
                  />
                ))}
              </List>
            )
          : donationList.length > 0 && (
              <List sx={{ marginLeft: '30px' }}>
                <MemberDonateTableRow
                  header
                  date={new Date()}
                  donatedMHL={0}
                  type={DonationType.Monthly}
                  issuedDate={new Date()}
                  onResend={() => {}}
                />
                {donationList.map((item) => (
                  <MemberDonateTableRow
                    isCanceled={item.isCanceled}
                    key={item.id}
                    date={item.donatedDate}
                    donatedMHL={item.amount}
                    type={item.type}
                    issuedDate={item.lastModifiedDate}
                    onResend={
                      member.email
                        ? () => {
                            setResendEmailError('');
                            setDonation(item);
                            setShowConfirmResendEmailPopUp(true);
                          }
                        : undefined
                    }
                    onCancel={
                      item.isCanceled || !member.email
                        ? undefined
                        : () => {
                            setCancelDonationError('');
                            setDonation(item);
                            setShowConfirmCancelDonationPopUp(true);
                          }
                    }
                  />
                ))}
              </List>
            )}
      </Box>
    );
  };

  return (
    <Box sx={{ paddingX: '40px', paddingTop: '30px' }}>
      <MHLPopUp
        height={'650px'}
        show={showEditMemberPopUp}
        error={editMemberError}
        title={'Edit Member'}
        onClose={() => setShowEditMemberPopUp(false)}
        cancelButtonText='Cancel'
        onCancel={() => setShowEditMemberPopUp(false)}
        submitButtonLoading={editingMember}
        submitButtonText='Save'
        onSubmit={_handleEdit}
      >
        <CreateMemberPopUpContent
          defaultMember={member}
          onChange={(member) => setUpdatedMember(member)}
        />
      </MHLPopUp>
      <MHLPopUp
        height={'670px'}
        show={showTopUpPopUp}
        error={topUpError}
        title={'Top Up account'}
        onClose={() => setShowTopUpPopUp(false)}
        cancelButtonText='Cancel'
        onCancel={() => setShowTopUpPopUp(false)}
        submitButtonLoading={loadingTopUp}
        submitButtonText='Submit'
        onSubmit={_handleTopUp}
      >
        <TopUpPopUpContent
          member={member}
          onChange={(topup) => setTopUp(topup)}
        />
      </MHLPopUp>

      <MHLPopUp
        height={'560px'}
        show={showDonationPopUp}
        error={donationError}
        title={'Donate'}
        onClose={() => setShowDonationPopUp(false)}
        cancelButtonText='Cancel'
        onCancel={() => setShowDonationPopUp(false)}
        submitButtonLoading={loadingDonation}
        submitButtonText='Submit'
        onSubmit={_handleDonation}
      >
        <DonationPopUpContent
          member={member}
          onChange={(donation) => {
            console.log('donation', donation);
            setDonation(donation);
          }}
        />
      </MHLPopUp>
      <MHLPopUp
        height={'270px'}
        show={showConfirmResendEmailPopUp}
        error={resendEmailError}
        title={'Resend Email'}
        onClose={() => {
          setShowConfirmResendEmailPopUp(false);
          setDonation(undefined);
        }}
        cancelButtonText='Cancel'
        onCancel={() => {
          setShowConfirmResendEmailPopUp(false);
          setDonation(undefined);
        }}
        submitButtonLoading={resendingEmail}
        submitButtonText='Resend'
        onSubmit={_handleResendEmail}
      >
        <Box sx={{ paddingX: '30px' }}>
          <Typography color={Colors.primaryColor}>
            Are you sure you want to resend an email to this address?
          </Typography>
          <Typography color={Colors.errorColor}>{member.email}</Typography>
        </Box>
      </MHLPopUp>

      <MHLPopUp
        height={'270px'}
        show={showConfirmCancelTopUpPopUp}
        error={cancelTopUpError}
        title={'Cancel Top Up'}
        onClose={() => {
          setShowConfirmCancelTopUpPopUp(false);
          setTopUp(emptyTopUp);
        }}
        cancelButtonText='No'
        onCancel={() => {
          setShowConfirmCancelTopUpPopUp(false);
          setTopUp(emptyTopUp);
        }}
        submitButtonLoading={loadingCancelTopUp}
        submitButtonText='Yes'
        onSubmit={_handleCancelTopUp}
      >
        <Typography color={Colors.primaryColor} sx={{ paddingX: '30px' }}>
          Are you sure you want to cancel this top up? This action will affect
          balance unit of user and current amount of yours.
        </Typography>
      </MHLPopUp>

      <MHLPopUp
        height={'270px'}
        show={showConfirmCancelDonationPopUp}
        error={cancelDonationError}
        title={'Cancel Donation'}
        onClose={() => {
          setShowConfirmCancelDonationPopUp(false);
          setDonation(undefined);
        }}
        cancelButtonText='No'
        onCancel={() => {
          setShowConfirmCancelDonationPopUp(false);
          setDonation(undefined);
        }}
        submitButtonLoading={loadingCancelDonation}
        submitButtonText='Yes'
        onSubmit={_handleCancelDonation}
      >
        <Typography color={Colors.primaryColor} sx={{ paddingX: '30px' }}>
          Are you sure you want to cancel this donation? This action will affect
          balance unit of user and current amount of yours.
        </Typography>
      </MHLPopUp>

      <Header />
      <Divider sx={{ marginTop: '30px', borderColor: Colors.borderColor }} />
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Details />
        <Divider
          orientation='vertical'
          flexItem
          sx={{ borderColor: Colors.borderColor }}
        />
        {(topUpList.length > 0 || donationList.length > 0) && <History />}
      </Box>
    </Box>
  );
}

export default MemberDetailsPage;
