import { AddCircle, SearchOutlined } from '@mui/icons-material';
import { Box, List } from '@mui/material';
import { useEffect, useState } from 'react';
import { Colors } from '../../colors';
import { MHLIconButton } from '../../components/buttons';
import { MHLInput } from '../../components/inputs';
import { MHLPagination } from '../../components/pagination';
import { MHLPopUp } from '../../components/popup';
import { useMemberRepository } from '../../data/member/memberRepository';
import { MemberTableRow } from './components/MemberTableRow';
import { useNavigate } from 'react-router-dom';
import TopUpPopUpContent from './TopUpPopUpContent';
import CreateMemberPopUpContent from './CreateMemberPopUpContent';
import { emptyMember, Member } from '../../data/member/memberModel';
import { useAuthService } from '../../data/auth/authService';
import { useTopUpRepository } from '../../data/topUp/topUpRepository';
import { getErrorMessage } from '../../util/helpers';
import { emptyTopUp, TopUp } from '../../data/topUp/topUpModel';

function MemberPage() {
  const navigate = useNavigate();

  const { user } = useAuthService();
  const { memberList, totalPages, fetchMembers, createMember } =
    useMemberRepository();
  const { createTopUp } = useTopUpRepository();

  const [page, setPage] = useState(0);
  const [search, setSearch] = useState<string>();

  const [showCreateMemberPopUp, setShowCreateMemberPopUp] = useState(false);
  const [member, setMember] = useState<Member>(emptyMember);
  const [creatingMember, setCreatingMember] = useState(false);
  const [createMemberError, setCreateMemberError] = useState<string>();

  const [showTopUpPopUp, setShowTopUpPopUp] = useState(false);
  const [topup, setTopUp] = useState<TopUp>(emptyTopUp);
  const [loadingTopUp, setLoadingTopUp] = useState(false);
  const [topUpError, setTopUpError] = useState<string>();

  useEffect(() => {
    fetchMembers({ page: page, size: 15 });
  }, [fetchMembers, page]);

  function _handleCreateMember() {
    setCreatingMember(true);
    createMember({ ...member, fundraiserId: user?.fundraiserId ?? '' })
      .then((member) => {
        setMember(member);
        setShowCreateMemberPopUp(false);
        setCreatingMember(false);
        setShowTopUpPopUp(true);
      })
      .catch((e) => {
        setCreateMemberError(getErrorMessage(e));
        setCreatingMember(false);
      });
  }

  function _handleTopUp() {
    setLoadingTopUp(true);
    createTopUp(topup)
      .then(() => {
        setShowTopUpPopUp(false);
        setLoadingTopUp(false);
        setMember(emptyMember);
        fetchMembers({ page: page, size: 15, search: search });
      })
      .catch((e) => {
        setTopUpError(getErrorMessage(e));
        setLoadingTopUp(false);
      });
  }

  const MemberList = () => {
    return (
      <List sx={{ marginY: '30px' }}>
        <MemberTableRow
          memberId={''}
          name={''}
          contactPoint={''}
          currentMHL={0}
          monthlyMHL={0}
        />
        {memberList.map((item) => (
          <MemberTableRow
            key={item.id}
            memberId={item.id}
            name={item.name}
            contactPoint={item.contactPoint}
            currentMHL={item.remainingAmount}
            monthlyMHL={item.monthlyDonation || 0}
            onClick={() => navigate(`/member/${item.id}`)}
            onTopUp={(e) => {
              e.stopPropagation();
              setTopUpError('');
              setMember(item);
              setShowTopUpPopUp(true);
            }}
          />
        ))}
      </List>
    );
  };

  return (
    <Box sx={{ paddingX: '40px', paddingY: '30px' }}>
      <MHLPopUp
        height={'580px'}
        show={showCreateMemberPopUp}
        error={createMemberError}
        title={'Create Member'}
        onClose={() => setShowCreateMemberPopUp(false)}
        cancelButtonText='Cancel'
        onCancel={() => setShowCreateMemberPopUp(false)}
        submitButtonLoading={creatingMember}
        submitButtonText='Save'
        onSubmit={_handleCreateMember}
      >
        <CreateMemberPopUpContent
          defaultMember={member}
          onChange={(member) => setMember(member)}
        />
      </MHLPopUp>
      <MHLPopUp
        height={'670px'}
        show={showTopUpPopUp}
        error={topUpError}
        title={'Top Up account'}
        onClose={() => setShowTopUpPopUp(false)}
        cancelButtonText='Cancel'
        onCancel={() => setShowTopUpPopUp(false)}
        submitButtonLoading={loadingTopUp}
        submitButtonText='Submit'
        onSubmit={_handleTopUp}
      >
        <TopUpPopUpContent
          member={member}
          onChange={(topup) => setTopUp(topup)}
        />
      </MHLPopUp>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <MHLInput
          placeholder='Search'
          icon={<SearchOutlined sx={{ color: Colors.textColor }} />}
          onChange={(text) => setSearch(text)}
          onComplete={() => fetchMembers({ search: search })}
        />
        <MHLIconButton
          icon={<AddCircle sx={{ color: Colors.white }} />}
          title={'Create Member'}
          onClick={() => {
            setMember(emptyMember);
            setCreateMemberError('');
            setShowCreateMemberPopUp(true);
          }}
        />
      </Box>
      {memberList.length > 0 && <MemberList />}
      {totalPages > 1 && (
        <Box sx={{ justifyContent: 'center', display: 'flex' }}>
          <MHLPagination
            page={page}
            total={totalPages}
            onChange={(value) => setPage(value)}
          />
        </Box>
      )}
    </Box>
  );
}

export default MemberPage;
