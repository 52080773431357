import { Box, Divider } from '@mui/material';
import { useState } from 'react';
import { MHLInput } from '../../components/inputs';
import { MHLPrice } from '../../components/price';
import { emptyBaseModel } from '../../data/common/commonModel';
import { Donation, DonationType } from '../../data/donation/donationModel';
import { useFundraiserRepository } from '../../data/fundraiser/fundraiserRepository';
import { Member } from '../../data/member/memberModel';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { MHLMemberInfo } from '../../components/info';
import { Colors } from '../../colors';

interface DonationPopUpProps {
  member: Member;
  onChange: (donation: Donation) => void;
}

const DonationPopUpContent: React.FC<DonationPopUpProps> = (props) => {
  const { member, onChange } = props;
  const { fundraiserList } = useFundraiserRepository();
  const fundraiser = fundraiserList[0];

  const [mhl, setMHL] = useState(0);
  const [remark, setRemark] = useState('');

  const [donation, setDonation] = useState<Donation>({
    id: 0,
    memberId: member.id,
    fundraiserId: fundraiser.id,
    member: member,
    type: DonationType.Monthly,
    amount: mhl,
    donatedDate: new Date().toISOString(),
    ...emptyBaseModel,
    remark: remark,
  });

  const [value, setValue] = useState<Dayjs | null>(dayjs());

  function _handleChangeMHL(text: string) {
    let newMHL = parseFloat(text);
    setMHL(newMHL);
    let newDonation: Donation = { ...donation, amount: newMHL };
    setDonation(newDonation);
    onChange(newDonation);
  }

  function _handleChangeDate(date: Dayjs | null) {
    console.log('date', date?.toISOString());
    setValue(date);
    let newDonation: Donation = {
      ...donation,
      donatedDate: (date ?? new Date()).toISOString(),
    };
    setDonation(newDonation);
    onChange(newDonation);
  }

  function _handleChangeRemark(text: string) {
    setRemark(text);
    let newDonation: Donation = { ...donation, remark: text };
    setDonation(newDonation);
    onChange(newDonation);
  }

  return (
    <Box sx={{ paddingX: '30px', overflow: 'auto' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
        }}
      >
        <MHLMemberInfo member={member} />
        <MHLPrice
          title='Balance Unit'
          unit='MHL'
          price={member.remainingAmount}
        />
      </Box>
      <Divider
        sx={{
          marginY: '20px',
          borderColor: Colors.borderColor,
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingBottom: '20px',
        }}
      >
        <MHLInput
          value={mhl.toString()}
          type='number'
          width='300px'
          title='MHL'
          onChange={_handleChangeMHL}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            value={value}
            onChange={_handleChangeDate}
            inputFormat='DD/MM/YYYY'
            renderInput={(params) => (
              <MHLInput
                params={{
                  inputProps: params.inputProps
                    ? {
                        readOnly: true,
                        value: params.inputProps?.value,
                        placeholder: 'DD/MM/YYYY',
                      }
                    : {},
                  InputProps: params.InputProps,
                  inputRef: params.inputRef,
                }}
                width='300px'
                title='Donation Date'
                onChange={() => {}}
              />
            )}
          />
        </LocalizationProvider>
      </Box>
      <MHLInput
        multiline
        width='100%'
        title='Remark'
        onChange={_handleChangeRemark}
      />
      <Box sx={{ height: '35px' }} />
    </Box>
  );
};

export default DonationPopUpContent;
