import { createBrowserRouter } from 'react-router-dom';
import App from './App';
import DonationPage from './view/donation/DonationPage';
import HomePage from './view/home/HomePage';
import MemberDetailsPage from './view/member/MemberDetailsPage';
import MemberPage from './view/member/MemberPage';
import TopUpPage from './view/topUp/TopUpPage';

const router = createBrowserRouter([
  {
    path: '',
    element: <App />,
    children: [
      {
        path: '',
        element: <HomePage />,
      },
      {
        path: 'member',
        element: <MemberPage />,
      },
      {
        path: 'member/:memberId',
        element: <MemberDetailsPage />,
      },
      {
        path: 'topup',
        element: <TopUpPage />,
      },
      {
        path: 'donation',
        element: <DonationPage />,
      },
    ],
  },
]);

export default router;
