import axios, { AxiosResponse } from 'axios';
import { atom, useRecoilState } from 'recoil';
import { LoginRequest, AuthResponse } from './authModel';
import qs from 'qs';
import { useMemo } from 'react';
import { getApiUrl } from '../../util/helpers';
import { User } from '../user/userModel';

interface AuthServiceState {
  readonly isLoggedOut: boolean;
  readonly isAuthenticated: boolean;
  readonly user?: User;
  readonly accessToken?: string;
}

const authServiceAtom = atom<AuthServiceState>({
  key: 'AuthServiceState',
  default: {
    isLoggedOut: false,
    isAuthenticated: false,
  },
});

function useAuthService() {
  const [state, setState] = useRecoilState(authServiceAtom);
  const { isLoggedOut, isAuthenticated, accessToken, user } = state;

  axios.defaults.withCredentials = true;

  axios.defaults.headers.common['Authorization'] = accessToken
    ? `Bearer ${accessToken}`
    : undefined;

  const handleAuthResponse = useMemo(
    () =>
      ({ data }: AxiosResponse<AuthResponse>) => {
        setState((state) => ({
          ...state,
          isLoggedOut: false,
          isAuthenticated: true,
          user: data.user,
          accessToken: data.accessToken,
        }));
      },
    [setState]
  );

  const setLoggedOut = useMemo(
    () => () => {
      setState((state) => ({
        ...state,
        isLoggedOut: true,
        isAuthenticated: false,
        user: undefined,
        accessToken: undefined,
      }));
    },
    [setState]
  );

  const login = useMemo(
    () => async (request: LoginRequest) => {
      return axios
        .post<AuthResponse>(`${getApiUrl()}/auth/login`, qs.stringify(request))
        .then(handleAuthResponse);
    },
    [handleAuthResponse]
  );

  const logout = useMemo(
    () => async () => {
      return axios.get(`${getApiUrl()}/auth/logout`).then(setLoggedOut);
    },
    [setLoggedOut]
  );

  const authenticate = useMemo(
    () => async () => {
      return axios
        .get<AuthResponse>(`${getApiUrl()}/auth/authenticate`)
        .then(handleAuthResponse)
        .catch(setLoggedOut);
    },
    [handleAuthResponse, setLoggedOut]
  );

  const setupAuth = useMemo(
    () => () => {
      authenticate();
      setInterval(authenticate, 3600 * 1000);
    },
    [authenticate]
  );

  return {
    isLoggedOut,
    isAuthenticated,
    user,
    setupAuth,
    login,
    logout,
  };
}

export { useAuthService };
