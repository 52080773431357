import axios from 'axios';
import { useMemo } from 'react';
import { atom, useRecoilState } from 'recoil';
import { getApiUrl } from '../../util/helpers';
import { PageData, PageFetchParams } from '../common/commonModel';
import { Fundraiser } from './fundraiserModel';

interface FundraiserRepoState {
  readonly fundraiserList: readonly Fundraiser[];
  readonly totalElements: number;
  readonly totalPages: number;
  readonly page: number;
  readonly size: number;
}

const fundraiserRepoAtom = atom<FundraiserRepoState>({
  key: 'FundraiserRepoState',
  default: {
    fundraiserList: [],
    totalElements: 0,
    totalPages: 0,
    page: 0,
    size: 0,
  },
});

function useFundraiserRepository() {
  const [state, setState] = useRecoilState(fundraiserRepoAtom);
  const { fundraiserList, totalElements, totalPages, page, size } = state;

  const fetchFundraiserById = useMemo(
    () => async (id: string) => {
      return axios
        .get<Fundraiser>(`${getApiUrl()}/resource/fundraiser/${id}`)
        .then(({ data }) =>
          setState((state) => ({
            ...state,
            fundraiserList: state.fundraiserList.concat(data),
          }))
        );
    },
    [setState]
  );

  const fetchFundraisers = useMemo(
    () => async (params?: PageFetchParams) => {
      return axios
        .get<PageData<Fundraiser>>(`${getApiUrl()}/resource/fundraiser`, {
          params: { ...params },
        })
        .then(({ data }) =>
          setState((state) => ({
            ...state,
            fundraiserList: data.content,
            totalElements: data.totalElements,
            totalPages: data.totalPages,
            page: data.number,
            size: data.size,
          }))
        );
    },
    [setState]
  );

  const createFundraiser = useMemo(
    () => async (fundraiser: Fundraiser) => {
      return axios
        .post<Fundraiser>(`${getApiUrl()}/resource/fundraiser`, fundraiser)
        .then(({ data }) =>
          setState((state) => ({
            ...state,
            fundraiserList: [data].concat(state.fundraiserList),
            totalElements: state.totalElements + 1,
          }))
        );
    },
    [setState]
  );

  const updateFundraiser = useMemo(
    () => async (fundraiser: Fundraiser) => {
      return axios
        .put<Fundraiser>(
          `${getApiUrl()}/resource/fundraiser/${fundraiser.id}`,
          fundraiser
        )
        .then(({ data }) =>
          setState((state) => ({
            ...state,
            fundraiserList: state.fundraiserList.map((e) =>
              e.id === data.id ? data : e
            ),
          }))
        );
    },
    [setState]
  );

  const deleteFundraiser = useMemo(
    () => async (id: string) => {
      return axios.delete(`${getApiUrl()}/resource/fundraiser/${id}`).then(() => {
        setState((state) => ({
          ...state,
          fundraiserList: state.fundraiserList.filter((e) => e.id !== id),
          totalElements: state.totalElements - 1,
        }));
      });
    },
    [setState]
  );

  return {
    fundraiserList,
    totalElements,
    totalPages,
    page,
    size,
    fetchFundraiserById,
    fetchFundraisers,
    createFundraiser,
    updateFundraiser,
    deleteFundraiser,
  };
}

export { useFundraiserRepository };
