import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { Colors } from '../../colors';
import { Dropdown } from '../../components/dropdown';
import { MHLInput } from '../../components/inputs';
import { MHLPrice } from '../../components/price';
import { emptyBaseModel } from '../../data/common/commonModel';
import { useCurrencyRepository } from '../../data/currency/currencyRepository';
import { useFundraiserRepository } from '../../data/fundraiser/fundraiserRepository';
import { Member } from '../../data/member/memberModel';
import { TopUp } from '../../data/topUp/topUpModel';
import { getCashAmount, getMHL } from '../../util/helpers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { MHLMemberInfo } from '../../components/info';

interface TopUpPopUpProps {
  member: Member;
  onChange: (topup: TopUp) => void;
}

const TopUpPopUpContent: React.FC<TopUpPopUpProps> = (props) => {
  const { member, onChange } = props;
  const { fundraiserList } = useFundraiserRepository();
  const fundraiser = fundraiserList[0];
  const { currencyList } = useCurrencyRepository();
  const [defaultCurrency, setDefaultCurrency] = useState(
    currencyList.filter((f) => f.id === fundraiser.defaultCurrencyId)[0]
  );

  const [amount, setAmount] = useState(0);
  const [mhl, setMHL] = useState(0);
  const [remark, setRemark] = useState('');
  const [topup, setTopUp] = useState<TopUp>({
    id: 0,
    memberId: member.id,
    fundraiserId: fundraiser.id,
    member: member,
    currencyId: defaultCurrency.id,
    currencyRate: defaultCurrency.pointToCashRate,
    cashAmount: amount,
    amount: mhl,
    ...emptyBaseModel,
    remark: remark,
  });

  function _handleChangeCurrency(text: string) {
    let currency = currencyList.filter((f) => f.id === text)[0];
    setDefaultCurrency(currency);
    let newAmount = getCashAmount(mhl, currency.pointToCashRate);
    setAmount(newAmount);

    let newTopUp: TopUp = {
      ...topup,
      currencyId: currency.id,
      currencyRate: currency.pointToCashRate,
      cashAmount: newAmount,
    };
    setTopUp(newTopUp);
    onChange(newTopUp);
  }

  function _handleChangeAmount(text: string) {
    let newAmount = parseFloat(text);
    let newMHL = getMHL(newAmount, defaultCurrency.pointToCashRate);
    setAmount(newAmount);
    setMHL(newMHL);

    let newTopUp: TopUp = { ...topup, amount: newMHL, cashAmount: newAmount };
    setTopUp(newTopUp);
    onChange(newTopUp);
  }

  function _handleChangeMHL(text: string) {
    let newMHL = parseFloat(text);
    let newAmount = getCashAmount(newMHL, defaultCurrency.pointToCashRate);
    setMHL(newMHL);
    setAmount(newAmount);

    let newTopUp: TopUp = { ...topup, amount: newMHL, cashAmount: newAmount };
    setTopUp(newTopUp);
    onChange(newTopUp);
  }

  function _handleChangeRemark(text: string) {
    setRemark(text);

    let newTopUp: TopUp = { ...topup, remark: text };
    setTopUp(newTopUp);
    onChange(newTopUp);
  }

  const [value, setValue] = useState<Dayjs | null>(dayjs());

  function _handleChangeCheckedBox(event: React.ChangeEvent<HTMLInputElement>) {
    let newTopUp: TopUp = {
      ...topup,
      oneTimeDonation: event.target.checked,
      oneTimeDonatedDate: event.target.checked
        ? new Date().toISOString()
        : undefined,
    };
    setTopUp(newTopUp);
    onChange(newTopUp);
  }

  function _handleChangeDate(date: Dayjs | null) {
    console.log('date', date?.toISOString());
    setValue(date);
    let newTopUp: TopUp = {
      ...topup,
      oneTimeDonatedDate: (date ?? new Date()).toISOString(),
    };
    setTopUp(newTopUp);
    onChange(newTopUp);
  }

  return (
    <Box sx={{ paddingX: '30px', overflow: 'auto' }}>
      <MHLMemberInfo member={member} />
      <Divider
        sx={{
          marginY: '20px',
          borderColor: Colors.borderColor,
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingBottom: '20px',
        }}
      >
        <MHLPrice
          title='Balance Unit'
          unit='MHL'
          price={member.remainingAmount}
        />
        {fundraiser.defaultCurrencyId && (
          <MHLPrice
            title='Current Amount'
            unit={defaultCurrency.id}
            price={getCashAmount(
              member.remainingAmount,
              defaultCurrency.pointToCashRate
            )}
          />
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'end',
          }}
        >
          <Typography variant='h6' color={Colors.primaryColor}>
            CURRENCY
          </Typography>
          <Dropdown
            list={currencyList.map((currency) => currency.id)}
            value={defaultCurrency.id}
            onChange={_handleChangeCurrency}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingBottom: '20px',
        }}
      >
        <MHLInput
          value={mhl.toString()}
          type='number'
          width='300px'
          title='MHL'
          onChange={_handleChangeMHL}
        />
        <MHLInput
          value={amount.toString()}
          type='number'
          width='300px'
          title='Amount*'
          onChange={_handleChangeAmount}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              disableRipple
              checked={topup.oneTimeDonation}
              onChange={_handleChangeCheckedBox}
            />
          }
          label='One-Time Donation'
          sx={{ color: Colors.primaryColor, paddingBottom: '10px' }}
        />
        {topup.oneTimeDonation && (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={value}
              onChange={_handleChangeDate}
              inputFormat='DD/MM/YYYY'
              renderInput={(params) => (
                <MHLInput
                  params={{
                    inputProps: params.inputProps
                      ? {
                          readOnly: true,
                          value: params.inputProps?.value,
                          placeholder: 'DD/MM/YYYY',
                        }
                      : {},
                    InputProps: params.InputProps,
                    inputRef: params.inputRef,
                  }}
                  width='300px'
                  title='Donation Date'
                  onChange={() => {}}
                />
              )}
            />
          </LocalizationProvider>
        )}
      </Box>
      <MHLInput
        multiline
        width='100%'
        title='Remark'
        onChange={_handleChangeRemark}
      />
      <Box sx={{ height: '35px' }} />
    </Box>
  );
};

export default TopUpPopUpContent;
