import { SearchOutlined } from '@mui/icons-material';
import { Box, List, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Colors } from '../../colors';
import { MHLInput } from '../../components/inputs';
import { MHLPagination } from '../../components/pagination';
import { MHLPopUp } from '../../components/popup';
import { Donation, DonationType } from '../../data/donation/donationModel';
import { useDonationRepository } from '../../data/donation/donationRepository';
import { getErrorMessage } from '../../util/helpers';
import { DonationTableRow } from './components/DonationTableRow';

function DonationPage() {
  const {
    donationList,
    totalPages,
    fetchDonations,
    resendEmail,
    cancelDonation,
  } = useDonationRepository();

  const [page, setPage] = useState(0);
  const [search, setSearch] = useState<string>();

  const [donation, setDonation] = useState<Donation>();
  const [showConfirmResendEmailPopUp, setShowConfirmResendEmailPopUp] =
    useState(false);
  const [resendingEmail, setResendingEmail] = useState(false);
  const [resendEmailError, setResendEmailError] = useState<string>();

  const [showConfirmCancelDonationPopUp, setShowConfirmCancelDonationPopUp] =
    useState(false);
  const [loadingCancelDonation, setLoadingCancelDonation] = useState(false);
  const [cancelDonationError, setCancelDonationError] = useState<string>();

  useEffect(() => {
    fetchDonations({ page: page, size: 15 });
  }, [fetchDonations, page]);

  function _handleResendEmail() {
    setResendingEmail(true);
    resendEmail(donation?.id.toString() ?? '')
      .then(() => {
        setShowConfirmResendEmailPopUp(false);
        setResendingEmail(false);
        setDonation(undefined);
      })
      .catch((e) => {
        setResendEmailError(getErrorMessage(e));
        setResendingEmail(false);
      });
  }

  function _handleCancelDonation() {
    setLoadingCancelDonation(true);
    cancelDonation(donation?.id.toString() ?? '')
      .then(() => {
        setShowConfirmCancelDonationPopUp(false);
        setLoadingCancelDonation(false);
        fetchDonations({ page: page, size: 15 });
      })
      .catch((e) => {
        setCancelDonationError(getErrorMessage(e));
        setLoadingCancelDonation(false);
      });
  }

  const TopUpList = () => {
    return (
      <List sx={{ marginY: '30px' }}>
        <DonationTableRow
          header
          date={new Date()}
          memberId={''}
          name={''}
          remark={''}
          donatedMHL={0}
          type={DonationType.Monthly}
          onResend={() => {}}
        />
        {donationList.map((item) => (
          <DonationTableRow
            isCanceled={item.isCanceled}
            key={item.id}
            date={item.createdDate}
            memberId={item.memberId}
            name={item.member.name}
            remark={item.remark ?? ''}
            donatedMHL={item.amount}
            type={item.type}
            onResend={
              item.member.email
                ? () => {
                    setResendEmailError('');
                    setDonation(item);
                    setShowConfirmResendEmailPopUp(true);
                  }
                : undefined
            }
            onCancel={
              item.isCanceled || !item.member.email
                ? undefined
                : () => {
                    setCancelDonationError('');
                    setDonation(item);
                    setShowConfirmCancelDonationPopUp(true);
                  }
            }
          />
        ))}
      </List>
    );
  };

  return (
    <Box sx={{ paddingX: '40px', paddingY: '30px' }}>
      <MHLPopUp
        height={'270px'}
        show={showConfirmResendEmailPopUp}
        error={resendEmailError}
        title={'Resend Email'}
        onClose={() => {
          setShowConfirmResendEmailPopUp(false);
          setDonation(undefined);
        }}
        cancelButtonText='Cancel'
        onCancel={() => {
          setShowConfirmResendEmailPopUp(false);
          setDonation(undefined);
        }}
        submitButtonLoading={resendingEmail}
        submitButtonText='Resend'
        onSubmit={() => _handleResendEmail()}
      >
        <Box sx={{ paddingX: '30px' }}>
          <Typography color={Colors.primaryColor}>
            Are you sure you want to resend an email to this address?
          </Typography>
          <Typography color={Colors.errorColor}>
            {donation?.member.email}
          </Typography>
        </Box>
      </MHLPopUp>

      <MHLPopUp
        height={'270px'}
        show={showConfirmCancelDonationPopUp}
        error={cancelDonationError}
        title={'Cancel Donation'}
        onClose={() => {
          setShowConfirmCancelDonationPopUp(false);
          setDonation(undefined);
        }}
        cancelButtonText='No'
        onCancel={() => {
          setShowConfirmCancelDonationPopUp(false);
          setDonation(undefined);
        }}
        submitButtonLoading={loadingCancelDonation}
        submitButtonText='Yes'
        onSubmit={_handleCancelDonation}
      >
        <Typography color={Colors.primaryColor} sx={{ paddingX: '30px' }}>
          Are you sure you want to cancel this donation? This action will affect
          balance unit of user and current amount of yours.
        </Typography>
      </MHLPopUp>

      <MHLInput
        placeholder='Enter Member ID'
        icon={<SearchOutlined sx={{ color: Colors.textColor }} />}
        onChange={(text) => setSearch(text)}
        onComplete={() => fetchDonations({ memberId: search })}
      />
      {donationList.length > 0 && <TopUpList />}
      {totalPages > 1 && (
        <Box sx={{ justifyContent: 'center', display: 'flex' }}>
          <MHLPagination
            page={page}
            total={totalPages}
            onChange={(value) => setPage(value)}
          />
        </Box>
      )}
    </Box>
  );
}

export default DonationPage;
