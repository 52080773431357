import {
  InputAdornment,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import { Colors } from '../colors';

interface InputProps {
  value?: string;
  type?: 'password' | 'number' | 'text';
  multiline?: boolean;
  width?: string;
  icon?: React.ReactNode;
  title?: string;
  placeholder?: string;
  onChange: (text: string) => void;
  onComplete?: () => void;
  params?: TextFieldProps;
}

export const MHLInput: React.FC<InputProps> = (props: InputProps) => {
  const {
    value,
    type = 'text',
    multiline = false,
    width,
    icon,
    title,
    placeholder = '',
    onChange,
    onComplete,
    params,
  } = props;

  return (
    <div>
      {title && (
        <Typography
          variant='body1'
          color={Colors.primaryColor}
          sx={{ marginBottom: '10px' }}
        >
          {title}
        </Typography>
      )}
      <TextField
        value={value}
        type={type}
        multiline={multiline}
        rows={2}
        placeholder={placeholder}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>{icon}</InputAdornment>
          ),
          inputProps: { min: 0 },
        }}
        sx={{
          width: width ?? '360px',
          height: '50px',
          '& .MuiOutlinedInput-root': {
            paddingX: '15px',
          },
        }}
        onChange={(event) => onChange(event.target.value)}
        onKeyDown={(event) => {
          if (event.key === 'Enter' && onComplete) {
            onComplete();
            event.preventDefault();
          }
        }}
        {...params}
      />
    </div>
  );
};
