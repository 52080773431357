import axios from 'axios';
import { useMemo } from 'react';
import { atom, useRecoilState } from 'recoil';
import { getApiUrl } from '../../util/helpers';
import { useAuthService } from '../auth/authService';
import { PageData } from '../common/commonModel';
import { TopUp, TopUpFetchParams } from './topUpModel';

interface TopUpRepoState {
  readonly topUpList: readonly TopUp[];
  readonly totalElements: number;
  readonly totalPages: number;
  readonly page: number;
  readonly size: number;
}

const topUpRepoAtom = atom<TopUpRepoState>({
  key: 'TopUpRepoState',
  default: {
    topUpList: [],
    totalElements: 0,
    totalPages: 0,
    page: 0,
    size: 0,
  },
});

function useTopUpRepository() {
  const [state, setState] = useRecoilState(topUpRepoAtom);
  const { topUpList, totalElements, totalPages, page, size } = state;

  const { user } = useAuthService();
  const frid = user?.fundraiserId;

  const fetchTopUps = useMemo(
    () => async (params?: TopUpFetchParams) => {
      return axios
        .get<PageData<TopUp>>(`${getApiUrl()}/resource/topUp`, {
          params: { frid, ...params },
        })
        .then(({ data }) =>
          setState((state) => ({
            ...state,
            topUpList: data.content,
            totalElements: data.totalElements,
            totalPages: data.totalPages,
            page: data.number,
            size: data.size,
          }))
        );
    },
    [setState, frid]
  );

  const createTopUp = useMemo(
    () => async (topUp: TopUp) => {
      return axios
        .post<TopUp>(`${getApiUrl()}/resource/topUp`, topUp)
        .then(({ data }) =>
          setState((state) => ({
            ...state,
            topUpList: [data].concat(state.topUpList),
            totalElements: state.totalElements + 1,
          }))
        );
    },
    [setState]
  );

  const cancelTopUp = useMemo(
    () => async (id: string) => {
      return axios
        .put(`${getApiUrl()}/resource/topUp/${id}/cancel?frid=${frid}`);
    },
    [frid]
  );

  return {
    topUpList,
    totalElements,
    totalPages,
    page,
    size,
    fetchTopUps,
    createTopUp,
    cancelTopUp,
  };
}

export { useTopUpRepository };
