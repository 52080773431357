import { Box } from '@mui/material';
import { useState } from 'react';
import { MHLInput } from '../../components/inputs';
import { useFundraiserRepository } from '../../data/fundraiser/fundraiserRepository';
import { Member } from '../../data/member/memberModel';

interface CreateMemberPopUpProps {
  defaultMember: Member;
  onChange: (member: Member) => void;
}

const CreateMemberPopUpContent: React.FC<CreateMemberPopUpProps> = (props) => {
  const { defaultMember, onChange } = props;
  const { fundraiserList } = useFundraiserRepository();
  const fundraiser = fundraiserList[0];
  const [member, setMember] = useState<Member>(defaultMember);

  return (
    <Box sx={{ paddingX: '30px', paddingBottom: '40px', overflow: 'auto' }}>
      {(fundraiser.dataMigration || !!defaultMember?.id) && (
        <MHLInput
          value={member.id}
          width='300px'
          title='Member ID'
          onChange={(text) => {
            setMember({ ...member, id: text });
            onChange({ ...member, id: text });
          }}
        />
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingY: '20px',
        }}
      >
        <MHLInput
          value={member.name}
          width='300px'
          title='Name*'
          onChange={(text) => {
            setMember({ ...member, name: text });
            onChange({ ...member, name: text });
          }}
        />
        <MHLInput
          value={member.email}
          width='300px'
          title='Email*'
          onChange={(text) => {
            setMember({ ...member, email: text });
            onChange({ ...member, email: text });
          }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingBottom: '20px',
        }}
      >
        <MHLInput
          value={member.contactPoint}
          width='300px'
          title='Contact Point'
          onChange={(text) => {
            setMember({ ...member, contactPoint: text });
            onChange({ ...member, contactPoint: text });
          }}
        />
        <MHLInput
          value={member.monthlyDonation.toString()}
          type='number'
          width='300px'
          title='Monthly MHL'
          onChange={(text) => {
            setMember({ ...member, monthlyDonation: parseFloat(text) });
            onChange({ ...member, monthlyDonation: parseFloat(text) });
          }}
        />
      </Box>
      <MHLInput
        value={member.remark}
        multiline
        width='100%'
        title='Note'
        onChange={(text) => {
          setMember({ ...member, remark: text });
          onChange({ ...member, remark: text });
        }}
      />
    </Box>
  );
};

export default CreateMemberPopUpContent;
