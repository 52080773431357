import { CheckCircle } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { Colors } from '../colors';
import { MHLInput } from '../components/inputs';
import { ChangePasswordParams } from '../data/user/userModel';

interface CreateMemberPopUpProps {
  onChange: (params: ChangePasswordParams, confirmNewPassword: string) => void;
}

const ChangePasswordPopUpContent: React.FC<CreateMemberPopUpProps> = ({ onChange }) => {
  const [params, setParams] = useState<ChangePasswordParams>({ oldPassword: '', newPassword: '' });
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>('');

  const Item: React.FC<{ title: string }> = ({ title }) => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', marginY: '10px' }}>
        <CheckCircle sx={{ height: '15px', marginRight: '5px', color: Colors.green }} />
        <Typography color={Colors.primaryColor}>{title}</Typography>
      </Box>
    )
  }

  return (
    <Box sx={{ paddingX: '30px', paddingBottom: '40px', overflow: 'auto', display: 'flex' }}>
      <Box sx={{ flex: 1 }}>
        <MHLInput
          type='password'
          width='300px'
          title='Current Password*'
          onChange={(text) => {
            setParams({ ...params, oldPassword: text });
            onChange({ ...params, oldPassword: text }, confirmNewPassword);
          }}
        />
        <Box sx={{ height: '20px' }} />
        <MHLInput
          type='password'
          width='300px'
          title='New Password*'
          onChange={(text) => {
            setParams({ ...params, newPassword: text });
            onChange({ ...params, newPassword: text }, confirmNewPassword);
          }}
        />
        <Box sx={{ height: '20px' }} />
        <MHLInput
          type='password'
          width='300px'
          title='Confirm New Password*'
          onChange={(text) => {
            setConfirmNewPassword(text);
            onChange(params, text);
          }}
        />
      </Box>
      <Box sx={{ flex: 1 }}>
        <Typography fontSize="18px" fontWeight="bold" color={Colors.primaryColor}>Password must contain at least</Typography>
        <Box sx={{ height: '10px' }} />
        <Item title='one uppercase letter' />
        <Item title='one lowercase letter' />
        <Item title='one special character' />
        <Item title='one number' />
      </Box>
    </Box>
  )
}

export default ChangePasswordPopUpContent;