import axios from 'axios';
import { useMemo } from 'react';
import { atom, useRecoilState } from 'recoil';
import { getApiUrl } from '../../util/helpers';
import { useAuthService } from '../auth/authService';
import { PageData, PageFetchParams } from '../common/commonModel';
import { ChangePasswordParams, User } from './userModel';

interface UserRepoState {
  readonly userList: readonly User[];
  readonly totalElements: number;
  readonly totalPages: number;
  readonly page: number;
  readonly size: number;
}

const userRepoAtom = atom<UserRepoState>({
  key: 'UserRepoState',
  default: {
    userList: [],
    totalElements: 0,
    totalPages: 0,
    page: 0,
    size: 0,
  },
});

function useUserRepository() {
  const [state, setState] = useRecoilState(userRepoAtom);
  const { userList, totalElements, totalPages, page, size } = state;

  const { user } = useAuthService();
  const frid = user?.fundraiserId;

  const fetchUsers = useMemo(
    () => async (params?: PageFetchParams) => {
      return axios
        .get<PageData<User>>(`${getApiUrl()}/resource/user`, {
          params: { frid, ...params },
        })
        .then(({ data }) =>
          setState((state) => ({
            ...state,
            userList: data.content,
            totalElements: data.totalElements,
            totalPages: data.totalPages,
            page: data.number,
            size: data.size,
          }))
        );
    },
    [setState, frid]
  );

  const createUser = useMemo(
    () => async (user: User) => {
      return axios
        .post<User>(`${getApiUrl()}/resource/user`, user)
        .then(({ data }) =>
          setState((state) => ({
            ...state,
            userList: [data].concat(state.userList),
            totalElements: state.totalElements + 1,
          }))
        );
    },
    [setState]
  );

  const updateUser = useMemo(
    () => async (user: User) => {
      return axios
        .put<User>(`${getApiUrl()}/resource/user/${user.id}`, user)
        .then(({ data }) =>
          setState((state) => ({
            ...state,
            userList: state.userList.map((e) => (e.id === data.id ? data : e)),
          }))
        );
    },
    [setState]
  );

  const deleteUser = useMemo(
    () => async (id: number) => {
      return axios.delete(`${getApiUrl()}/resource/user/${id}`).then(() => {
        setState((state) => ({
          ...state,
          userList: state.userList.filter((e) => e.id !== id),
          totalElements: state.totalElements - 1,
        }));
      });
    },
    [setState]
  );

  const changePassword = useMemo(() => async (params: ChangePasswordParams) => {
    return axios.put<User>(`${getApiUrl()}/resource/user/password/change`, params);
  }, []);

  return {
    userList,
    totalElements,
    totalPages,
    page,
    size,
    fetchUsers,
    createUser,
    updateUser,
    deleteUser,
    changePassword,
  };
}

export { useUserRepository };
