import { ArrowDropDown } from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  Fade,
  Paper,
  Popper,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { Colors } from '../colors';
import { MHLPopUp } from '../components/popup';
import { useAuthService } from '../data/auth/authService';
import { useFundraiserRepository } from '../data/fundraiser/fundraiserRepository';
import { ChangePasswordParams } from '../data/user/userModel';
import { useUserRepository } from '../data/user/userRepository';
import { getErrorMessage } from '../util/helpers';
import ChangePasswordPopUpContent from './ChangePasswordPopUpContent';

function Profile() {
  const { user, logout } = useAuthService();
  const { fundraiserList } = useFundraiserRepository();
  const fundraiser = fundraiserList[0];
  const { changePassword } = useUserRepository();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [showPopUp, setShowPopUp] = useState(false);

  const [showChangePWPopUp, setShowChangePWPopUp] = useState(false);
  const [changePWParams, setChangePWParams] = useState<ChangePasswordParams>({ oldPassword: '', newPassword: '' });
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>('');
  const [loadingChangePW, setLoadingChangePW] = useState(false);
  const [changePWError, setChangePWError] = useState<string>();

  const _handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setShowPopUp(!showPopUp);
  };

  const _handleChangePassword = () => {
    setChangePWError('');
    if (confirmNewPassword !== changePWParams.newPassword) {
      setChangePWError('Passwords do not match.');
      return;
    }
    setLoadingChangePW(true);
    changePassword(changePWParams).then(() => {
      setShowChangePWPopUp(false);
      setLoadingChangePW(false);
    }).catch((e) => {
      setLoadingChangePW(false);
      setChangePWError(getErrorMessage(e));
    });
  }

  return (
    <>
      <MHLPopUp
        height={'525px'}
        show={showChangePWPopUp}
        error={changePWError}
        title={'Change Password'}
        onClose={() => setShowChangePWPopUp(false)}
        cancelButtonText='Cancel'
        onCancel={() => setShowChangePWPopUp(false)}
        submitButtonLoading={loadingChangePW}
        submitButtonText='Change'
        onSubmit={_handleChangePassword}
      >
        <ChangePasswordPopUpContent
          onChange={(params: ChangePasswordParams, confirmNewPassword: string) => {
            setChangePWParams(params);
            setConfirmNewPassword(confirmNewPassword);
          }}
        />
      </MHLPopUp>
      <Popper
        open={showPopUp}
        anchorEl={anchorEl}
        placement='bottom-end'
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={200}>
            <Paper
              elevation={0}
              sx={{
                marginTop: '20px',
                padding: '30px',
                width: '400px',
                borderRadius: '20px',
                borderStyle: 'solid',
                borderWidth: '1px',
                borderColor: Colors.borderColor,
                color: Colors.white,
                boxShadow: '0px 0px 50px 0px rgba(36, 62, 150, 0.05)',
              }}
            >
              {user?.email &&
                <Box sx={{ flex: 1, display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                  <Typography color={Colors.textColor}>Email</Typography>
                  <Box sx={{ width: '40px' }} />
                  <Typography noWrap color={Colors.primaryColor}>
                    {user?.email}
                  </Typography>
                </Box>}
              <Box sx={{ flex: 1, display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                <Typography color={Colors.textColor}>Password</Typography>
                <Box sx={{ width: '40px' }} />
                <Button
                  disableRipple
                  variant='text'
                  sx={{ color: Colors.blue, padding: 0 }}
                  onClick={() => {
                    setShowPopUp(false);
                    setChangePWError('');
                    setShowChangePWPopUp(true);
                  }}
                >
                  <Typography>Change</Typography>
                </Button>
              </Box>
              {fundraiser?.defaultCurrencyId &&
                <Box sx={{ flex: 1, display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                  <Typography color={Colors.textColor}>Default Currency</Typography>
                  <Box sx={{ width: '40px' }} />
                  <Typography noWrap color={Colors.primaryColor}>
                    {fundraiser?.defaultCurrencyId}
                  </Typography>
                </Box>}
              {fundraiser?.fundraiserCashList && fundraiser?.fundraiserCashList.length > 0 &&
                <>
                  <Typography color={Colors.textColor}>Currency</Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '10px',
                      flexWrap: 'wrap',
                      marginY: '20px',
                    }}
                  >
                    {fundraiser?.fundraiserCashList.map((currency) => (
                      <Typography
                        color={Colors.primaryColor}
                        sx={{
                          width: '100px',
                          height: '40px',
                          display: 'flex',
                          borderRadius: '10px',
                          backgroundColor: Colors.accentColor,
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        {currency.currencyId}
                      </Typography>
                    ))}
                  </Box>
                  <Divider sx={{ color: Colors.borderColor, marginBottom: '15px' }} />
                </>}
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  disableRipple
                  variant='text'
                  sx={{ color: Colors.blue, padding: 0 }}
                  onClick={logout}
                >
                  <Typography fontSize='20px' fontWeight='400'>
                    Logout
                  </Typography>
                </Button>
              </Box>
            </Paper>
          </Fade>
        )}
      </Popper>
      <Button
        disableElevation
        disableRipple
        sx={{ padding: 0, height: '80px' }}
        onClick={_handleClick}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            marginRight: '20px',
          }}
        >
          <Typography
            fontSize='20px'
            fontWeight='400'
            color={Colors.primaryColor}
          >
            {user?.name}
          </Typography>
          <Typography fontSize='14px' fontWeight='400' color={Colors.textColor}>
            {user?.fundraiserId}
          </Typography>
        </Box>
        <ArrowDropDown sx={{ color: Colors.primaryColor }} />
      </Button>
    </>
  );
}

export default Profile;
