import axios from 'axios';
import { useMemo } from 'react';
import { atom, useRecoilState } from 'recoil';
import { getApiUrl } from '../../util/helpers';
import { PageData, PageFetchParams } from '../common/commonModel';
import { Currency } from './currencyModel';

interface CurrencyRepoState {
  readonly currencyList: readonly Currency[];
  readonly totalElements: number;
  readonly totalPages: number;
  readonly page: number;
  readonly size: number;
}

const currencyRepoAtom = atom<CurrencyRepoState>({
  key: 'CurrencyRepoState',
  default: {
    currencyList: [],
    totalElements: 0,
    totalPages: 0,
    page: 0,
    size: 0,
  },
});

function useCurrencyRepository() {
  const [state, setState] = useRecoilState(currencyRepoAtom);
  const { currencyList, totalElements, totalPages, page, size } = state;

  const fetchCurrencys = useMemo(
    () => async (params?: PageFetchParams) => {
      return axios
        .get<PageData<Currency>>(`${getApiUrl()}/resource/currency`, {
          params: { ...params, size: 100 },
        })
        .then(({ data }) =>
          setState((state) => ({
            ...state,
            currencyList: data.content,
            totalElements: data.totalElements,
            totalPages: data.totalPages,
            page: data.number,
            size: data.size,
          }))
        );
    },
    [setState]
  );

  const createCurrency = useMemo(
    () => async (currency: Currency) => {
      return axios
        .post<Currency>(`${getApiUrl()}/resource/currency`, currency)
        .then(({ data }) =>
          setState((state) => ({
            ...state,
            currencyList: [data].concat(state.currencyList),
            totalElements: state.totalElements + 1,
          }))
        );
    },
    [setState]
  );

  const updateCurrency = useMemo(
    () => async (currency: Currency) => {
      return axios
        .put<Currency>(
          `${getApiUrl()}/resource/currency/${currency.id}`,
          currency
        )
        .then(({ data }) =>
          setState((state) => ({
            ...state,
            currencyList: state.currencyList.map((e) =>
              e.id === data.id ? data : e
            ),
          }))
        );
    },
    [setState]
  );

  const deleteCurrency = useMemo(
    () => async (id: string) => {
      return axios.delete(`${getApiUrl()}/resource/currency/${id}`).then(() => {
        setState((state) => ({
          ...state,
          currencyList: state.currencyList.filter((e) => e.id !== id),
          totalElements: state.totalElements - 1,
        }));
      });
    },
    [setState]
  );

  return {
    currencyList,
    totalElements,
    totalPages,
    page,
    size,
    fetchCurrencys,
    createCurrency,
    updateCurrency,
    deleteCurrency,
  };
}

export { useCurrencyRepository };
