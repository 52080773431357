import axios from 'axios';
import { useMemo } from 'react';
import { atom, useRecoilState } from 'recoil';
import { getApiUrl } from '../../util/helpers';
import { useAuthService } from '../auth/authService';
import { PageData, PageFetchParams } from '../common/commonModel';
import { CashCollection } from './cashCollectionModel';

interface CashCollectionRepoState {
  readonly cashCollectionList: readonly CashCollection[];
  readonly totalElements: number;
  readonly totalPages: number;
  readonly page: number;
  readonly size: number;
}

const cashCollectionRepoAtom = atom<CashCollectionRepoState>({
  key: 'CashCollectionRepoState',
  default: {
    cashCollectionList: [],
    totalElements: 0,
    totalPages: 0,
    page: 0,
    size: 0,
  },
});

function useCashCollectionRepository() {
  const [state, setState] = useRecoilState(cashCollectionRepoAtom);
  const { cashCollectionList, totalElements, totalPages, page, size } = state;

  const { user } = useAuthService();
  const frid = user?.fundraiserId;

  const fetchCashCollections = useMemo(
    () => async (params?: PageFetchParams) => {
      return axios
        .get<PageData<CashCollection>>(
          `${getApiUrl()}/resource/cashCollection`,
          {
            params: { frid, ...params },
          }
        )
        .then(({ data }) =>
          setState((state) => ({
            ...state,
            cashCollectionList: data.content,
            totalElements: data.totalElements,
            totalPages: data.totalPages,
            page: data.number,
            size: data.size,
          }))
        );
    },
    [setState, frid]
  );

  const createCashCollection = useMemo(
    () => async (cashCollection: CashCollection) => {
      return axios
        .post<CashCollection>(
          `${getApiUrl()}/resource/cashCollection`,
          cashCollection
        )
        .then(({ data }) =>
          setState((state) => ({
            ...state,
            cashCollectionList: [data].concat(state.cashCollectionList),
            totalElements: state.totalElements + 1,
          }))
        );
    },
    [setState]
  );

  return {
    cashCollectionList,
    totalElements,
    totalPages,
    page,
    size,
    fetchCashCollections,
    createCashCollection,
  };
}

export { useCashCollectionRepository };
