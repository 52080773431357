import { Box, Typography } from "@mui/material";
import { Colors } from "../../../colors";
import { MHLButton } from "../../../components/buttons";
import { getString } from "../../../util/helpers";

interface AmountTableRowProps {
  header?: boolean;
  currency: string;
  amount: number;
  onCollect?: (e: any) => void;
}

export const AmountTableRow: React.FC<AmountTableRowProps> = (props: AmountTableRowProps) => {
  const {
    header = false,
    currency,
    amount,
    onCollect,
  } = props;

  const Item: React.FC<{ title: string, alignment?: 'start' | 'center' | 'end' }> = ({ title, alignment = 'center' }) => {
    return (
      <Typography
        variant='body1'
        color={Colors.primaryColor}
        sx={{
          flex: 1,
          display: 'flex',
          justifyContent: alignment
        }}
      >
        {title}
      </Typography>
    )
  }

  return (
    <Box
      sx={[{
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '50px',
        paddingX: '40px',
        paddingY: '20px',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderTopWidth: 0,
        borderColor: Colors.borderColor,
      },
      header && {
        borderTopWidth: '1px',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        borderColor: Colors.borderColor,
        borderBottomColor: Colors.primaryColor,
        backgroundColor: Colors.accentColor,
      }]}
    >
      <Item title={header ? 'Currency' : getString(currency)} alignment='start' />
      <Item title={header ? 'Amount' : amount.toLocaleString()} />
      {header && onCollect ?
        <Box sx={{ width: '120px' }} /> :
        onCollect && <MHLButton
          width="120px"
          height="40px"
          title={"Collect"}
          color="#E4E9FA"
          titleColor={Colors.primaryColor}
          onClick={!onCollect ? () => { } : onCollect}
        />}
    </Box>
  )
}