import {
  BaseModel,
  emptyBaseModel,
  PageFetchParams,
} from '../common/commonModel';
import { Member, emptyMember } from '../member/memberModel';

interface TopUp extends BaseModel {
  readonly id: number;
  readonly memberId: string;
  readonly fundraiserId: string;
  readonly member: Member;
  readonly currencyId: string;
  readonly currencyRate: number;
  readonly cashAmount: number;
  readonly amount: number;
  readonly oneTimeDonation?: boolean;
  readonly oneTimeDonatedDate?: string;
  
  readonly isCanceled?: boolean;
}

const emptyTopUp: TopUp = {
  id: 0,
  memberId: '',
  fundraiserId: '',
  member: emptyMember,
  currencyId: '',
  currencyRate: 0,
  cashAmount: 0,
  amount: 0,
  ...emptyBaseModel,
};

interface TopUpFetchParams extends PageFetchParams {
  memberId?: string;
}

export { type TopUp, emptyTopUp, type TopUpFetchParams };
