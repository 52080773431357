interface BaseModel {
  readonly createdBy: string;
  readonly createdDate: string;
  readonly lastModifiedBy: string;
  readonly lastModifiedDate: string;
  readonly remark?: string;
}

const emptyBaseModel: BaseModel = {
  createdBy: '',
  createdDate: '',
  lastModifiedBy: '',
  lastModifiedDate: '',
};

interface PageFetchParams {
  size?: number;
  page?: number;
  search?: string;
  frid?: string;
}

interface PageData<T> {
  readonly content: readonly T[];
  readonly size: number;
  readonly number: number;
  readonly totalPages: number;
  readonly totalElements: number;
}

export { type BaseModel, emptyBaseModel, type PageData, type PageFetchParams };
