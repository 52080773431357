import { Box, Button, Typography } from '@mui/material';
import { Colors } from '../../../colors';
import { MHLButton } from '../../../components/buttons';
import { getString } from '../../../util/helpers';

interface MemberTableRowProps {
  memberId: string;
  name: string;
  contactPoint: string;
  currentMHL: number;
  monthlyMHL: number;
  onClick?: () => void;
  onTopUp?: (e: any) => void;
}

export const MemberTableRow: React.FC<MemberTableRowProps> = (
  props: MemberTableRowProps
) => {
  const {
    memberId,
    name,
    contactPoint,
    currentMHL,
    monthlyMHL,
    onClick,
    onTopUp,
  } = props;

  const Item: React.FC<{
    title: string;
    alignment?: 'start' | 'center' | 'end';
  }> = ({ title, alignment = 'center' }) => {
    return (
      <Typography
        variant='body1'
        color={Colors.primaryColor}
        sx={{
          flex: 1,
          display: 'flex',
          justifyContent: alignment,
        }}
      >
        {title}
      </Typography>
    );
  };

  return (
    <Button
      disabled={!onClick}
      variant='outlined'
      disableElevation
      disableRipple
      fullWidth
      onClick={onClick}
      sx={{
        height: '50px',
        paddingX: '40px',
        '&:hover': {
          backgroundColor: Colors.accentColor,
          borderWidth: '1px',
          borderTopWidth: 0,
          borderColor: Colors.borderColor,
        },
        borderWidth: '1px',
        borderTopWidth: 0,
        borderColor: Colors.borderColor,
        borderRadius: 0,
        '&:disabled': {
          borderTopWidth: '1px',
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px',
          borderColor: Colors.borderColor,
          borderBottomColor: Colors.primaryColor,
          backgroundColor: Colors.accentColor,
        },
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingY: '20px',
        }}
      >
        <Item
          title={!onClick ? 'Member ID' : getString(memberId)}
          alignment='start'
        />
        <Item title={!onClick ? 'Name' : getString(name)} />
        <Item title={!onClick ? 'Contact Point' : getString(contactPoint)} />
        <Item title={!onClick ? 'MHL' : currentMHL.toLocaleString()} />
        <Item title={!onClick ? 'Monthly MHL' : monthlyMHL.toLocaleString()} />
        {!onClick ? (
          <Box sx={{ width: '120px' }} />
        ) : (
          <MHLButton
            width='120px'
            height='40px'
            title={'Top Up'}
            color='#E4E9FA'
            titleColor={Colors.primaryColor}
            onClick={!onTopUp ? () => { } : onTopUp}
          />
        )}
      </Box>
    </Button>
  );
};
