import { AppBar, Box, Link, Toolbar } from '@mui/material';
import { useLocation } from 'react-router-dom';
import Logo from '../assets/logo.png';
import { MHLAppBarButton } from '../components/buttons';
import Profile from './Profile';

interface Page {
  label: string;
  route: string;
}

const pages: Page[] = [
  { label: 'Home', route: '/' },
  { label: 'Members', route: '/member' },
  { label: 'Top Up Records', route: '/topup' },
  { label: 'Donation Records', route: '/donation' },
];

const MyAppBar: React.FC = () => {
  const location = useLocation();

  return (
    <AppBar
      position='fixed'
      sx={{ boxShadow: '0px 4px 4px 0px rgba(36, 62, 150, 0.05)' }}
    >
      <Toolbar style={{ height: 80, padding: '40px' }}>
        <Link href='/'>
          <img src={Logo} height={50} alt='logo' />
        </Link>
        <Box sx={{ flexGrow: 1, display: 'flex', ml: '80px' }}>
          {pages.map((page) => (
            <MHLAppBarButton
              key={page.route + page.label}
              selected={location.pathname === page.route}
              title={page.label}
              href={page.route}
            />
          ))}
        </Box>
        <Profile />
      </Toolbar>
    </AppBar>
  );
};

export default MyAppBar;
