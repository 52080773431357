import React from 'react';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';
import { LinkProps, createTheme } from '@mui/material';
import { Colors } from '../colors';

const LinkBehavior = React.forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
>((props, ref) => {
  const { href, ...other } = props;
  return <RouterLink ref={ref} to={href} {...other} />;
});

const theme = createTheme({
  palette: {
    primary: {
      main: Colors.primaryColor,
    },
    background: {
      default: Colors.backgroundColor,
    },
  },
  components: {
    MuiAppBar: {
      defaultProps: {
        position: 'static',
        elevation: 0,
        color: 'inherit',
      },
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
        style: {
          textDecoration: 'none',
        },
      } as LinkProps,
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          '&:hover': {
            background: 'none',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: '10px',
            backgroundColor: '#F1F3FB',
            '& input': {
              color: Colors.primaryColor,
            },
            '& fieldset': {
              borderColor: Colors.borderColor,
              borderWidth: '2px',
            },
            '&:hover fieldset': {
              borderColor: Colors.primaryColor,
              borderWidth: '2px',
            },
            '&.Mui-focused fieldset': {
              borderColor: Colors.primaryColor,
              borderWidth: '2px',
            },
          },
        },
      },
    },
    // MuiTooltip: {
    //   styleOverrides: {
    //     tooltip: {
    //       backgroundColor: Colors.errorColor,
    //     },
    //   },
    // },
    MuiContainer: {
      styleOverrides: {
        root: {
          borderRadius: 20,
        },
      },
    },
  },
});

export default theme;
