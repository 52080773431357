import axios from 'axios';
import { useMemo } from 'react';
import { atom, useRecoilState } from 'recoil';
import { getApiUrl } from '../../util/helpers';
import { useAuthService } from '../auth/authService';
import { PageData, PageFetchParams } from '../common/commonModel';
import { Member } from './memberModel';

interface MemberRepoState {
  readonly memberList: readonly Member[];
  readonly totalElements: number;
  readonly totalPages: number;
  readonly page: number;
  readonly size: number;
}

const memberRepoAtom = atom<MemberRepoState>({
  key: 'MemberRepoState',
  default: {
    memberList: [],
    totalElements: 0,
    totalPages: 0,
    page: 0,
    size: 0,
  },
});

function useMemberRepository() {
  const [state, setState] = useRecoilState(memberRepoAtom);
  const { memberList, totalElements, totalPages, page, size } = state;

  const { user } = useAuthService();
  const frid = user?.fundraiserId;

  const fetchMembers = useMemo(
    () => async (params?: PageFetchParams) => {
      return axios
        .get<PageData<Member>>(`${getApiUrl()}/resource/member`, {
          params: { frid, ...params },
        })
        .then(({ data }) =>
          setState((state) => ({
            ...state,
            memberList: data.content,
            totalElements: data.totalElements,
            totalPages: data.totalPages,
            page: data.number,
            size: data.size,
          }))
        );
    },
    [setState, frid]
  );

  const fetchMemberById = useMemo(
    () => async (id: string) => {
      return axios.get<Member>(`${getApiUrl()}/resource/member/${id}`);
    },
    []
  );

  const createMember = useMemo(
    () => async (member: Member) => {
      return axios
        .post<Member>(`${getApiUrl()}/resource/member`, member)
        .then(({ data }) => {
          setState((state) => ({
            ...state,
            memberList: [data].concat(state.memberList),
            totalElements: state.totalElements + 1,
          }));
          return data;
        });
    },
    [setState]
  );

  const updateMember = useMemo(
    () => async (member: Member) => {
      return axios
        .put<Member>(`${getApiUrl()}/resource/member/${member.id}`, member)
        .then(({ data }) =>
          setState((state) => ({
            ...state,
            memberList: state.memberList.map((e) =>
              e.id === data.id ? data : e
            ),
          }))
        );
    },
    [setState]
  );

  const deleteMember = useMemo(
    () => async (id: string) => {
      return axios.delete(`${getApiUrl()}/resource/member/${id}`).then(() => {
        setState((state) => ({
          ...state,
          memberList: state.memberList.filter((e) => e.id !== id),
          totalElements: state.totalElements - 1,
        }));
      });
    },
    [setState]
  );

  return {
    memberList,
    totalElements,
    totalPages,
    page,
    size,
    fetchMemberById,
    fetchMembers,
    createMember,
    updateMember,
    deleteMember,
  };
}

export { useMemberRepository };
