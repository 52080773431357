import { BaseModel, emptyBaseModel } from '../common/commonModel';

interface CashCollection extends BaseModel {
  readonly id: number;
  readonly fundraiserId: string;
  readonly currencyId: string;
  readonly cashAmount: number;
}

const emptyCashCollection: CashCollection = {
  id: 0,
  fundraiserId: '',
  currencyId: '',
  cashAmount: 0,
  ...emptyBaseModel,
};

export { type CashCollection, emptyCashCollection };
