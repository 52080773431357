import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { Colors } from '../../../colors';
import { getFormattedDate } from '../../../util/helpers';
import Moment from 'moment';
import { DonationType } from '../../../data/donation/donationModel';
import { Autorenew, Error, RemoveCircleOutline } from '@mui/icons-material';

interface MemberDonateTableRowProps {
  isCanceled?: boolean;
  header?: boolean;
  date?: Moment.MomentInput;
  donatedMHL: number;
  type: DonationType;
  issuedDate: Moment.MomentInput;
  onResend?: () => void;
  onCancel?: () => void;
}

export const MemberDonateTableRow: React.FC<MemberDonateTableRowProps> = (
  props: MemberDonateTableRowProps
) => {
  const {
    isCanceled = false,
    header = false,
    date,
    donatedMHL,
    type,
    issuedDate,
    onResend,
    onCancel,
  } = props;

  const Item: React.FC<{
    isCanceled: boolean;
    toolTip?: string;
    title: string;
    alignment?: 'start' | 'center' | 'end';
  }> = ({ isCanceled, toolTip, title, alignment = 'center' }) => {
    return (
      <Typography
        variant='body1'
        color={isCanceled ? Colors.errorColor : Colors.primaryColor}
        sx={{
          flex: 1,
          display: 'flex',
          justifyContent: alignment,
        }}
      >
        {title}
        {toolTip && (
          <Tooltip
            title={toolTip ?? ''}
            sx={{
              color: Colors.errorColor,
              ml: '10px',
            }}
          >
            <Error />
          </Tooltip>
        )}
      </Typography>
    );
  };

  return (
    <Box
      sx={[
        {
          flex: 1,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '50px',
          paddingX: '40px',
          paddingY: '20px',
          borderStyle: 'solid',
          borderWidth: '1px',
          borderTopWidth: 0,
          borderColor: Colors.borderColor,
        },
        header && {
          borderTopWidth: '1px',
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px',
          borderColor: Colors.borderColor,
          borderBottomColor: Colors.primaryColor,
          backgroundColor: Colors.accentColor,
        },
      ]}
    >
      <Item
        isCanceled={isCanceled}
        title={header ? 'Date' : getFormattedDate(date)}
        alignment='start'
      />
      <Item
        isCanceled={isCanceled}
        toolTip={
          !header && isCanceled
            ? 'This donation record has been canceled'
            : undefined
        }
        title={header ? 'MHL' : donatedMHL.toLocaleString()}
      />
      <Item
        isCanceled={isCanceled}
        title={
          header
            ? 'Type'
            : type === DonationType.Monthly
            ? 'Monthly'
            : 'One Time'
        }
      />
      <Item
        isCanceled={isCanceled}
        title={header ? 'Issued Date' : getFormattedDate(issuedDate)}
      />
      {header ? (
        <Box sx={{ width: '80px' }} />
      ) : (
        <Box>
          <Tooltip title='Resend'>
            <IconButton
              disabled={!onResend}
              disableRipple
              sx={{
                color: Colors.blue,
                '&:disabled': {
                  color: Colors.borderColor,
                },
              }}
              onClick={onResend}
            >
              <Autorenew />
            </IconButton>
          </Tooltip>
          <Tooltip title='Cancel'>
            <IconButton
              disabled={!onCancel}
              disableRipple
              sx={{
                color: Colors.blue,
                '&:disabled': {
                  color: Colors.borderColor,
                },
              }}
              onClick={onCancel}
            >
              <RemoveCircleOutline />
            </IconButton>
          </Tooltip>
        </Box>
      )}
    </Box>
  );
};
