import axios from 'axios';
import { useMemo } from 'react';
import { atom, useRecoilState } from 'recoil';
import { getApiUrl } from '../../util/helpers';
import { useAuthService } from '../auth/authService';
import { PageData } from '../common/commonModel';
import { Donation, DonationFetchParams } from './donationModel';

interface DonationRepoState {
  readonly donationList: readonly Donation[];
  readonly totalElements: number;
  readonly totalPages: number;
  readonly page: number;
  readonly size: number;
}

const donationRepoAtom = atom<DonationRepoState>({
  key: 'DonationRepoState',
  default: {
    donationList: [],
    totalElements: 0,
    totalPages: 0,
    page: 0,
    size: 0,
  },
});

function useDonationRepository() {
  const [state, setState] = useRecoilState(donationRepoAtom);
  const { donationList, totalElements, totalPages, page, size } = state;

  const { user } = useAuthService();
  const frid = user?.fundraiserId;

  const fetchDonations = useMemo(
    () => async (params?: DonationFetchParams) => {
      return axios
        .get<PageData<Donation>>(`${getApiUrl()}/resource/donation`, {
          params: { frid, ...params },
        })
        .then(({ data }) =>
          setState((state) => ({
            ...state,
            donationList: data.content,
            totalElements: data.totalElements,
            totalPages: data.totalPages,
            page: data.number,
            size: data.size,
          }))
        );
    },
    [setState, frid]
  );

  const createDonation = useMemo(
    () => async (donation: Donation) => {
      return axios
        .post<Donation>(`${getApiUrl()}/resource/donation`, donation)
        .then(({ data }) =>
          setState((state) => ({
            ...state,
            donationList: [data].concat(state.donationList),
            totalElements: state.totalElements + 1,
          }))
        );
    },
    [setState]
  );

  const cancelDonation = useMemo(
    () => async (id: string) => {
      return axios
        .put(`${getApiUrl()}/resource/donation/${id}/cancel?frid=${frid}`);
    },
    [frid]
  );

  const resendEmail = useMemo(
    () => async (id: string) => {
      return axios
        .put(`${getApiUrl()}/resource/donation/${id}/email/resend?frid=${frid}`);
    },
    [frid]
  );

  return {
    donationList,
    totalElements,
    totalPages,
    page,
    size,
    fetchDonations,
    createDonation,
    resendEmail,
    cancelDonation,
  };
}

export { useDonationRepository };
