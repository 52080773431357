import Moment from 'moment';

export const getString = (string?: string) => {
  return typeof string === 'string' && string.length !== 0 ? string : '-';
};

export const getFormattedDate = (date?: Moment.MomentInput) => {
  let formattedDate = Moment(date).format('DD/MM/YYYY');
  return formattedDate;
};

export const getCashAmount = (mhl: number, rate: number) => {
  return mhl * rate;
}

export const getMHL = (amount: number, rate: number) => {
  return amount / rate;
}

export const getApiUrl = () => process.env.REACT_APP_API_URL;

export const getErrorMessage = (error: any) => {
  if (error.response.data.errors && error.response.data.errors.length > 0) {
    return error.response.data.errors[0];
  }
  return error.message;
}
