import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { Colors } from '../../../colors';
import { getFormattedDate, getString } from '../../../util/helpers';
import Moment from 'moment';
import { Error, RemoveCircleOutline } from '@mui/icons-material';

interface TopUpTableRowProps {
  isCanceled?: boolean;
  header?: boolean;
  date: Moment.MomentInput;
  memberId: string;
  name: string;
  remark: string;
  currency: string;
  amount: number;
  topupMHL: number;
  onCancel?: () => void;
}

export const TopUpTableRow: React.FC<TopUpTableRowProps> = (
  props: TopUpTableRowProps
) => {
  const {
    isCanceled = false,
    header = false,
    date,
    memberId,
    name,
    remark,
    currency,
    amount,
    topupMHL,
    onCancel,
  } = props;

  const Item: React.FC<{
    isCanceled: boolean;
    toolTip?: string;
    title: string;
    alignment?: 'start' | 'center' | 'end';
  }> = ({ isCanceled, toolTip, title, alignment = 'center' }) => {
    return (
      <Typography
        variant='body1'
        color={isCanceled ? Colors.errorColor : Colors.primaryColor}
        sx={{
          flex: 1,
          display: 'flex',
          justifyContent: alignment,
        }}
      >
        {title}
        {toolTip && (
          <Tooltip
            title={toolTip ?? ''}
            sx={{
              color: Colors.errorColor,
              ml: '10px',
            }}
          >
            <Error />
          </Tooltip>
        )}
      </Typography>
    );
  };

  return (
    <Box
      sx={[
        {
          flex: 1,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '50px',
          paddingX: '40px',
          paddingY: '20px',
          borderStyle: 'solid',
          borderWidth: '1px',
          borderTopWidth: 0,
          borderColor: Colors.borderColor,
        },
        header && {
          borderTopWidth: '1px',
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px',
          borderColor: Colors.borderColor,
          borderBottomColor: Colors.primaryColor,
          backgroundColor: Colors.accentColor,
        },
      ]}
    >
      <Item
        isCanceled={isCanceled}
        title={header ? 'Date' : getFormattedDate(date)}
        alignment='start'
      />
      <Item
        isCanceled={isCanceled}
        title={header ? 'Member ID' : getString(memberId)}
      />
      <Item isCanceled={isCanceled} title={header ? 'Name' : getString(name)} />
      <Item
        isCanceled={isCanceled}
        title={header ? 'Remark' : getString(remark)}
      />
      <Item
        isCanceled={isCanceled}
        toolTip={
          !header && isCanceled
            ? 'This top up record has been canceled'
            : undefined
        }
        title={header ? 'Amount' : `${currency} ${amount.toLocaleString()}`}
      />
      <Item
        isCanceled={isCanceled}
        title={header ? 'MHL' : topupMHL.toLocaleString()}
      />
      {header ? (
        <Box sx={{ width: '40px' }} />
      ) : (
        <Tooltip title='Cancel'>
          <IconButton
            disabled={!onCancel}
            disableRipple
            sx={{
              color: Colors.blue,
              '&:disabled': {
                color: Colors.borderColor,
              },
            }}
            onClick={onCancel}
          >
            <RemoveCircleOutline />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
};
