import { SearchOutlined } from '@mui/icons-material';
import { Box, List, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Colors } from '../../colors';
import { MHLInput } from '../../components/inputs';
import { MHLPagination } from '../../components/pagination';
import { MHLPopUp } from '../../components/popup';
import { TopUp } from '../../data/topUp/topUpModel';
import { useTopUpRepository } from '../../data/topUp/topUpRepository';
import { getErrorMessage } from '../../util/helpers';
import { TopUpTableRow } from './components/TopUpTableRow';

function TopUpPage() {
  const { topUpList, totalPages, fetchTopUps, cancelTopUp } =
    useTopUpRepository();

  const [page, setPage] = useState(0);
  const [search, setSearch] = useState<string>();

  const [topup, setTopUp] = useState<TopUp>();
  const [showConfirmCancelTopUpPopUp, setShowConfirmCancelTopUpPopUp] =
    useState(false);
  const [loadingCancelTopUp, setLoadingCancelTopUp] = useState(false);
  const [cancelTopUpError, setCancelTopUpError] = useState<string>();

  useEffect(() => {
    fetchTopUps({ page: page, size: 15 });
  }, [fetchTopUps, page]);

  function _handleCancelTopUp() {
    setLoadingCancelTopUp(true);
    cancelTopUp(topup?.id.toString() ?? '')
      .then(() => {
        setShowConfirmCancelTopUpPopUp(false);
        setLoadingCancelTopUp(false);
        setTopUp(undefined);
        fetchTopUps({ page: page, size: 15 });
      })
      .catch((e) => {
        setCancelTopUpError(getErrorMessage(e));
        setLoadingCancelTopUp(false);
      });
  }

  const TopUpList = () => {
    return (
      <List sx={{ marginY: '30px' }}>
        <TopUpTableRow
          header
          date={new Date()}
          memberId={''}
          name={''}
          remark={''}
          currency={''}
          amount={0}
          topupMHL={0}
        />
        {topUpList.map((item) => (
          <TopUpTableRow
            isCanceled={item.isCanceled}
            key={item.id}
            date={item.createdDate}
            memberId={item.memberId}
            name={item.member.name}
            remark={item.remark ?? ''}
            currency={item.currencyId}
            amount={item.cashAmount}
            topupMHL={item.amount}
            onCancel={
              item.isCanceled || !item.member.email
                ? undefined
                : () => {
                    setCancelTopUpError('');
                    setTopUp(item);
                    setShowConfirmCancelTopUpPopUp(true);
                  }
            }
          />
        ))}
      </List>
    );
  };

  return (
    <Box sx={{ paddingX: '40px', paddingY: '30px' }}>
      <MHLPopUp
        height={'270px'}
        show={showConfirmCancelTopUpPopUp}
        error={cancelTopUpError}
        title={'Cancel Top Up'}
        onClose={() => {
          setShowConfirmCancelTopUpPopUp(false);
          setTopUp(undefined);
        }}
        cancelButtonText='No'
        onCancel={() => {
          setShowConfirmCancelTopUpPopUp(false);
          setTopUp(undefined);
        }}
        submitButtonLoading={loadingCancelTopUp}
        submitButtonText='Yes'
        onSubmit={_handleCancelTopUp}
      >
        <Typography color={Colors.primaryColor} sx={{ paddingX: '30px' }}>
          Are you sure you want to cancel this top up? This action will affect
          balance unit of user and current amount of yours.
        </Typography>
      </MHLPopUp>

      <MHLInput
        placeholder='Enter Member ID'
        icon={<SearchOutlined sx={{ color: Colors.textColor }} />}
        onChange={(text) => setSearch(text)}
        onComplete={() => fetchTopUps({ memberId: search })}
      />
      {topUpList.length > 0 && <TopUpList />}
      {totalPages > 1 && (
        <Box sx={{ justifyContent: 'center', display: 'flex' }}>
          <MHLPagination
            page={page}
            total={totalPages}
            onChange={(value) => setPage(value)}
          />
        </Box>
      )}
    </Box>
  );
}

export default TopUpPage;
