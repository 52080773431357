import { Box, Button, Collapse, List, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Colors } from '../../colors';
import { MHLPagination } from '../../components/pagination';
import { MHLPopUp } from '../../components/popup';
import { useAuthService } from '../../data/auth/authService';
import { CashCollection, emptyCashCollection } from '../../data/cashCollection/cashCollectionModel';
import { useCashCollectionRepository } from '../../data/cashCollection/cashCollectionRepository';
import { emptyFundraiserCash, FundraiserCash } from '../../data/fundraiser/fundraiserModel';
import { useFundraiserRepository } from '../../data/fundraiser/fundraiserRepository';
import { getErrorMessage } from '../../util/helpers';
import CashCollectionPopUpContent from './CashCollectionPopUpContent';
import { AmountTableRow } from './components/AmountTableRow';
import { CollectionTableRow } from './components/CollectionTableRow';

function HomePage() {
  const { user } = useAuthService();
  const { fundraiserList, fetchFundraiserById } = useFundraiserRepository();
  const { cashCollectionList, totalPages, fetchCashCollections, createCashCollection } = useCashCollectionRepository();
  const [page, setPage] = useState(0);

  const [seeAll, setSeeAll] = useState(false);

  const [showCashCollectionPopUp, setShowCashCollectionPopUp] = useState(false);
  const [fundraiserCash, setFundraiserCash] = useState<FundraiserCash>(emptyFundraiserCash);
  const [cashCollection, setCashCollection] = useState<CashCollection>(emptyCashCollection);
  const [loadingCashCollection, setLoadingCashCollection] = useState(false);
  const [cashCollectionError, setCashCollectionError] = useState<string>();

  useEffect(() => {
    if (user?.fundraiserId) fetchFundraiserById(user?.fundraiserId);
    fetchCashCollections({ page: page, size: seeAll ? 15 : 5 });
  }, [user?.fundraiserId, fetchFundraiserById, fetchCashCollections, page, totalPages, seeAll]);

  function _handleCashCollection() {
    setLoadingCashCollection(true);
    createCashCollection(cashCollection)
      .then(() => {
        setShowCashCollectionPopUp(false);
        setLoadingCashCollection(false);
        if (user?.fundraiserId) fetchFundraiserById(user?.fundraiserId);
        fetchCashCollections({ page: page, size: seeAll ? 15 : 5 });
      })
      .catch(e => {
        setCashCollectionError(getErrorMessage(e));
        setLoadingCashCollection(false);
      })
  }

  const CurrentAmountList = () => {
    return (
      <Box sx={{ flex: 1 }}>
        <Typography fontSize='24px' fontWeight='bold' color={Colors.primaryColor}>Current Amount</Typography>
        <List sx={{ marginY: '10px' }}>
          <AmountTableRow header currency={''} amount={0} onCollect={() => { }} />
          {fundraiserList[0].fundraiserCashList?.map(item =>
            <AmountTableRow
              key={item.id}
              currency={item.currencyId}
              amount={item.remainingCashAmount}
              onCollect={() => {
                setFundraiserCash(item);
                setCashCollection(emptyCashCollection);
                setCashCollectionError('');
                setShowCashCollectionPopUp(true);
              }}
            />)}
        </List>
      </Box>
    )
  }

  const TotalAmountList = () => {
    return (
      <Box sx={{ flex: 1 }}>
        <Typography fontSize='24px' fontWeight='bold' color={Colors.primaryColor}>Total Amount</Typography>
        <List sx={{ marginY: '10px' }}>
          <AmountTableRow header currency={''} amount={0} />
          {fundraiserList[0].fundraiserCashList?.map(item =>
            <AmountTableRow
              key={item.id}
              currency={item.currencyId}
              amount={item.totalCollectedCashAmount + item.remainingCashAmount}
            />)}
        </List>
      </Box>
    )
  }

  const CollectionList = () => {
    return (
      <Box sx={{ flex: 1 }}>
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Typography fontSize='24px' fontWeight='bold' color={Colors.primaryColor}>Collection Records</Typography>
          <Button
            disableRipple
            variant="text"
            sx={{ color: Colors.blue }}
            onClick={() => setSeeAll(!seeAll)}
          >
            {seeAll ? 'Hide' : 'See All'}
          </Button>
        </Box>
        <List sx={{ marginY: '10px' }}>
          <CollectionTableRow header date={new Date()} remark={''} currency={''} amount={0} />
          {cashCollectionList.map(item =>
            <CollectionTableRow
              key={item.id}
              date={item.createdDate}
              remark={item.remark ?? ''}
              currency={item.currencyId}
              amount={item.cashAmount}
            />)}
        </List>
      </Box>
    )
  }

  return (
    <Box sx={{ paddingX: '40px', paddingY: '30px' }}>
      <MHLPopUp
        height={'560px'}
        show={showCashCollectionPopUp}
        error={cashCollectionError}
        title={'Collection'}
        onClose={() => setShowCashCollectionPopUp(false)}
        cancelButtonText='Cancel'
        onCancel={() => setShowCashCollectionPopUp(false)}
        submitButtonLoading={loadingCashCollection}
        submitButtonText='Collect'
        onSubmit={_handleCashCollection}
      >
        <CashCollectionPopUpContent fundraiserCash={fundraiserCash} onChange={cashCollection => setCashCollection(cashCollection)} />
      </MHLPopUp>

      <Collapse in={!seeAll}>
        {fundraiserList.length > 0 && fundraiserList[0].fundraiserCashList && fundraiserList[0].fundraiserCashList.length > 0 &&
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <CurrentAmountList />
            <Box sx={{ width: '40px' }} />
            <TotalAmountList />
          </Box>}
      </Collapse>
      {cashCollectionList.length > 0 && <CollectionList />}
      {totalPages > 1 && seeAll &&
        <Box sx={{ justifyContent: 'center', display: 'flex' }}>
          <MHLPagination
            page={page}
            total={totalPages}
            onChange={(value) => setPage(value)} />
        </Box>}
    </Box>
  );
}

export default HomePage;
