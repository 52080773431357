import { Box } from "@mui/material";
import { useState } from "react";
import { MHLInput } from "../../components/inputs";
import { MHLPrice } from "../../components/price";
import { emptyBaseModel } from "../../data/common/commonModel";
import { CashCollection } from "../../data/cashCollection/cashCollectionModel";
import { FundraiserCash } from "../../data/fundraiser/fundraiserModel";

interface CashCollectionPopUpProps {
  fundraiserCash: FundraiserCash;
  onChange: (cashCollection: CashCollection) => void;
}

const CashCollectionPopUpContent: React.FC<CashCollectionPopUpProps> = (props) => {
  const { fundraiserCash, onChange } = props;

  const [amount, setAmount] = useState(0);
  const [remark, setRemark] = useState('');

  const [cashCollection, setCashCollection] = useState<CashCollection>({
    id: 0,
    fundraiserId: fundraiserCash.fundraiserId,
    currencyId: fundraiserCash.currencyId,
    cashAmount: 0,
    ...emptyBaseModel,
    remark: remark,
  });

  function _handleChangeMHL(text: string) {
    let newAmount = parseInt(text);
    setAmount(newAmount);
    let newCashCollection: CashCollection = { ...cashCollection, cashAmount: newAmount };
    setCashCollection(newCashCollection);
    onChange(newCashCollection);
  }

  function _handleChangeRemark(text: string) {
    setRemark(text);
    let newCashCollection: CashCollection = { ...cashCollection, remark: text };
    setCashCollection(newCashCollection);
    onChange(newCashCollection);
  }

  return (
    <Box sx={{ paddingX: '30px', overflow: 'auto' }}>
      <MHLPrice
        title="Current Amount"
        unit={fundraiserCash.currencyId}
        price={fundraiserCash.remainingCashAmount}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingY: '20px'
        }}
      >
        <MHLInput
          value={amount.toString()}
          type="number"
          width='300px'
          title='Amount'
          onChange={_handleChangeMHL}
        />
      </Box>
      <MHLInput
        multiline
        width='100%'
        title='Remark'
        onChange={_handleChangeRemark}
      />
      <Box sx={{ height: '35px' }} />
    </Box>
  )
}

export default CashCollectionPopUpContent;